import { WorksitesCard } from '@models/worksites/components/worksitesList/WorksitesCard';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { v4 } from 'uuid';
import { HeaderContext } from '@context/headerContext';
import { GlobalContext } from '@context/globalContext';
import {
  exportVue,
  getWorksitesList,
} from '@models/worksites/apiRequests/worksitesRequests';
import { LoaderWorksitesList } from '@components/loaders/worksites/LoaderWorksitesList';
import { WORKSITES_LIST_TABS } from '@models/worksites/utils/tab';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import {
  FilterRequestData,
  ISortAndFilterType,
} from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '@context/authContext';
import { ADMIN_ROUTES, WORKSITES_ROUTES } from '@utils/routesUrls';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { ResultsPerPageButton } from '@components/atomic/pagination/ResultsPerPageButton';
import { blueOpx } from '@assets/color';
import { SubHeader } from '@components/subHeader/SubHeader';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Loader } from '@components/atomic/Loader';
import { AddFatIcon, DownloadIcon } from '@assets/images/svgComponents';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { ROLES } from '@utils/roles';
import { FilterCriteriaEnum } from '@utils/enums';
import { IWorksiteDetails } from '../utils/worksitesTypes';
import { getKeyByValue } from '../utils/utils';

interface WorksitesListProps {
  adminMode?: boolean;
}

function WorksitesList({ adminMode }: WorksitesListProps): JSX.Element {
  const { t } = useTranslation();
  const {
    globalEnum,
    updateDisplayModalUpgradeSubscription,
    userView,
    roleUser,
  } = useContext(GlobalContext);
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const { worksitesListDatas, updateWorksitesListDatas } =
    useContext(WorksitesContext);

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stepStr = queryParams.get('step');
  const step = stepStr ? parseInt(stepStr, 10) : null;

  const [currentPage, setCurrentPage] = useState<number>(1); // Ajouter un état pour la page actuelle
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const numberLoaderCards = 5;
  const [resultsPerPage, setResultsPerPage] = useState<number>(5);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);
  const [tabActive, setTabActive] = useState<string>('');
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const preFilter = useMemo((): FilterRequestData[] => {
    const filterData: FilterRequestData[] = [];
    const company = query.get('company');
    if (company) {
      filterData.push({
        criteria: FilterCriteriaEnum.ENTITY,
        ids: [parseInt(company as string, 10)],
      });
    }
    return filterData;
  }, [query]);

  const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>({ filters: preFilter });

  const exportListVue = () => {
    const status = getKeyByValue(globalEnum.worksite_ui_status, tabActive, t);
    exportVue(
      setExportLoading,
      String(userView?.company_name),
      tabActive,
      status,
      sortAndFilterData
    );
  };

  const handleNavigationButtonClick = (label: string) => {
    switch (label) {
      case t('worksites.new_work_site'):
        navigate('/worksites/new');
        break;
      case t('worksites.exportvue'):
        exportListVue();
        break;
      default:
        break;
    }
  };

  const getList = async (
    page: number,
    listData?: IWorksiteDetails[],
    customSortAndFilterData?: ISortAndFilterType
  ) => {
    let tabStatus =
      tabActive === t('worksites.all')
        ? undefined
        : getKeyByValue(globalEnum.worksite_ui_status, tabActive, t);

    if (location.hash === '#simulations') {
      tabStatus = 3;
    }

    const res = await getWorksitesList(
      updateWorksitesListDatas,
      setIsLoadingList,
      page,
      setIsLoading,
      listData,
      tabStatus,
      customSortAndFilterData || sortAndFilterData,
      adminMode,
      resultsPerPage
    );

    if (res) {
      setPaginationData({
        current_page: res.current_page,
        last_page: res.last_page,
        total: res.total,
      });
      if (res.current_page > pagesDatas.length) {
        setPagesDatas([
          ...pagesDatas,
          {
            data: res.data,
            meta: {
              current_page: res.current_page,
              last_page: res.last_page,
              total: res.total,
            },
          },
        ]);
      }
    }
  };

  const pageAlreadyLoad = (page: number) => {
    if (pagesDatas[page - 1]) {
      updateWorksitesListDatas(pagesDatas[page - 1].data);
      setPaginationData(pagesDatas[page - 1].meta);
    } else {
      getList(page);
    }
  };

  /* const infiniteScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight < scrollHeight + 1 &&
        scrollTop + clientHeight > scrollHeight - 1
      ) {
        if (!isLoading && totalWorksites > worksitesListDatas.length) {
          setNumberPageList(numberPageList + 1);
          setIsLoading(true);
          getList(numberPageList + 1, worksitesListDatas);
        } else if (
          !endOfList &&
          totalWorksites === worksitesListDatas.length &&
          numberPageList > 1
        ) {
          setEndOfList(true);
        }
      }
    }
  }; */

  const onClickTabsLeftPart = (title: string) => {
    navigate(
      adminMode ? ADMIN_ROUTES.ADMIN_WORKSITES : WORKSITES_ROUTES.WORKSITES
    );
    setTabActive(title);
    setCurrentPage(1);
    setIsLoading(true);
    setPaginationData(undefined);
    setPagesDatas([]);
    setIsLoading(false);
  };

  useEffect(() => {
    updateTitleHeader(`${t('sidebar.worksites')}`);
    refreshHeader();
    getList(1);
  }, []);

  useEffect(() => {
    if (Object.keys(globalEnum).length > 1) {
      let customSortAndFilterData;

      if (step && step >= 1 && step <= 8) {
        customSortAndFilterData = {
          filters: [
            {
              criteria: 2,
              ids: [step],
            },
          ],
        };
      }
      setCurrentPage(1);
      setPaginationData(undefined);
      setPagesDatas([]);
      getList(1, undefined, customSortAndFilterData);
      setIsLoading(false);
    }
  }, [tabActive, globalEnum, step]);

  useEffect(() => {
    if (sortAndFilterData || resultsPerPage) {
      setPagesDatas([]);
      setCurrentPage(1);
      getList(1);
    }
  }, [sortAndFilterData, resultsPerPage]);

  const leftPartSubHeader = useMemo(() => {
    return (
      <TabsLeftPart
        titlesList={Object.values(WORKSITES_LIST_TABS(t, user))}
        onClick={(title: string) => onClickTabsLeftPart(title)}
        activeButton={
          location.hash === '#simulations'
            ? WORKSITES_LIST_TABS(t, user).SIMULATIONS
            : WORKSITES_LIST_TABS(t, user).ALL
        }
      />
    );
  }, [tabActive]);

  const rightPartSubHeader: JSX.Element = (
    <>
      <div className="flex gap-3">
        <ButtonOpx
          label={t('worksites.exportvue')}
          icon={exportLoading ? <Loader /> : <DownloadIcon />}
          type="secondary"
          onClick={() => {
            if (user?.is_freemium) {
              updateDisplayModalUpgradeSubscription(true);
            } else {
              handleNavigationButtonClick(t('worksites.exportvue'));
            }
          }}
        />
        <SortAndFilter
          page={adminMode ? 'ADMIN_CHANTIERS' : 'CHANTIERS'}
          onSort={(column, direction) =>
            sortList(column, direction, setSortAndFilterData)
          }
          onFilter={(filters) => filterList(filters, setSortAndFilterData)}
          preFilter={preFilter}
        />
      </div>
      {[ROLES.PRODUCTION, ROLES.GESTION].includes(roleUser) &&
        user?.permissions_names.includes('creer-chantier') && (
          <ButtonOpx
            label={t('worksites.new_work_site')}
            icon={<AddFatIcon />}
            type="primary"
            onClick={() =>
              handleNavigationButtonClick(t('worksites.new_work_site'))
            }
            dataTestId="new_worksite"
          />
        )}
    </>
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <div className="w-full" data-test-id="worksites">
      <SubHeader leftPart={leftPartSubHeader} rightPart={rightPartSubHeader} />
      <div className="flex justify-between">
        <div className="mt-4">
          {paginationData?.total !== undefined && (
            <div>
              {t('pagination.total_results')} : {paginationData.total}
            </div>
          )}
        </div>
        <div className="mb-4">
          <ResultsPerPageButton
            resultsPerPage={resultsPerPage}
            onChange={(value) => {
              setResultsPerPage(value);
              setCurrentPage(1);
              getList(1);
            }}
            options={[5, 10, 20, 50, 100, 250]}
            colorPagination={blueOpx}
          />
        </div>
      </div>
      {isLoadingList ? (
        <div className="w-full overflow-y-scroll flex-col flex space-y-[1.5rem]">
          {[...Array(numberLoaderCards)].map(() => (
            <div key={v4()}>
              <LoaderWorksitesList />
            </div>
          ))}
        </div>
      ) : (
        <div className="w-full" data-test-id="worksites_list">
          {worksitesListDatas.length === 0 ? (
            <div
              className="list_noElements flex items-center justify-center h-36 text-danger"
              data-test-id="no_result"
            >
              {t('list.no_result')}
            </div>
          ) : (
            <div className="w-full flex-col flex space-y-[1.5rem] ">
              {worksitesListDatas.map((details: IWorksiteDetails) => (
                <WorksitesCard
                  key={details.id}
                  worksiteDetails={details}
                  adminMode={adminMode}
                />
              ))}
              {isLoading && <LoaderWorksitesList />}
            </div>
          )}
        </div>
      )}
      <ListPagination
        getData={(page) => {
          setCurrentPage(page);
          if (page > pagesDatas.length) {
            getList(page);
          } else {
            pageAlreadyLoad(page);
          }
        }}
        paginationData={paginationData}
        resultsPerPage={resultsPerPage}
        currentPage={currentPage}
      />
    </div>
  );
}

export { WorksitesList };

WorksitesList.defaultProps = {
  adminMode: false,
};
