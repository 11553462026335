import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ColorCube } from '@components/atomic/ColorCube';
import {
  AddIcon,
  CheckIcon,
  ClockAlertOutline,
  CrossIcon,
  DownloadIcon,
  EyeIcon,
  FileIcon,
  MoreDotsIcon,
  TrashIcon,
} from '@assets/images/svgComponents';
import { blueOpx, green, grey, orange, red, textGrey } from '@assets/color';
import { useTranslation } from 'react-i18next';
import {
  DOCUMENT_STATE_STRING,
  DOCUMENT_STATUS_ENUM,
  DOCUMENT_TYPES,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_MB,
  customStatusDoc,
} from '@utils/utils';
import {
  handleUploadClick,
  shortenStringWithEllipsis,
  transformObjectList,
} from '@utils/functions';
import { ROLES } from '@utils/roles';
import { GlobalContext } from '@context/globalContext';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Loader } from '@components/atomic/Loader';
import {
  getLinkedFileDocumentAnalysis,
  validateOrRejectDocument,
} from '@apiRequests/globalRequests';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { initialDocument } from '@utils/initialState';
import { toast } from 'react-toastify';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { fileTypeEnum, RequestOrMessageOrChangeEnum } from '@utils/enums';
import { useLocation, useParams } from 'react-router-dom';
import { IRequestTypes } from '@models/requests/types/requestTypes';
import { ModalDocumentAnalysis } from '@components/documents/ModalDocumentAnalysis';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { TextWithCheckboxOrToggle } from '@components/atomic/inputs/controls/TextWithCheckboxOrToggle';
import {
  IDocumentAnalysis,
  ILinkedFile,
  ILinkedFilesGrouped,
} from '../../types/globalTypes';
import DocumentStatusTag from './DocumentStatusTag';

type ISelectedActionsType = {
  document: ILinkedFile;
  action: 'validate' | 'refuse';
  rejectionComment?: string;
};

interface INewDocumentCardProps {
  groupedDocuments: ILinkedFilesGrouped;
  onSelect: (document: ILinkedFile) => void;
  updateParentDocuments?: () => Promise<void>;
  closeDropdown: boolean;
  setCloseDropdown: Dispatch<SetStateAction<boolean>>;
  index: number;
  importMode: boolean;
  readOnly: boolean;
  onUpload?: (
    file: File,
    originalLinkedFile: ILinkedFile,
    customfield_id: number | null
  ) => Promise<void>;
  onDelete?: (document: ILinkedFile) => Promise<void>;
  showAnalysis?: boolean;
}

function DocumentCardInList({
  groupedDocuments,
  onSelect,
  onUpload,
  onDelete,
  updateParentDocuments,
  closeDropdown,
  setCloseDropdown,
  index,
  importMode,
  readOnly,
  showAnalysis,
}: INewDocumentCardProps) {
  const { t } = useTranslation();
  const { title, required, file_type /* , documents */ } = groupedDocuments;
  const { roleUser, globalEnum, documentActive } = useContext(GlobalContext);
  const { worksiteDetails } = useContext(WorksitesContext);
  const {
    listRequests,
    updateDetailRequest,
    updateRequestOrChangeEnumInCard,
    listRequestsGroupAll,
  } = useContext(RequestAndActivityIntoModalOrCardContext);
  const { updateSignedQuotesToSend, signedQuotesToSend } = useContext(
    WorksiteCreationContext
  );
  const { id: relatedId } = useParams();
  const { pathname } = useLocation();

  const [selectedActions, setSelectedActions] = useState<
    ISelectedActionsType[]
  >([]);
  const [selectedOperations, setSelectedOperations] = useState<
    { relation_id: number; name: string }[]
  >([]);
  const [fileToChange, setFileToChange] = useState<ILinkedFile | null>(null);
  const [resetSelect, setResetSelect] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isDocumentReportModal, setDocumentReportModal] = useState(false);
  const [documentReportModalContent, setDocumentReportModalContent] =
    useState<IDocumentAnalysis | null>(null);
  const [documentReportActiveFile, setDocumentReportActiveFile] =
    useState<ILinkedFile | null>(null);

  const hiddenFileInputCard = useRef<HTMLInputElement>(null);

  const uploadFileEnabled =
    (groupedDocuments.is_operation_document &&
      groupedDocuments.operations?.length === 1) ||
    (groupedDocuments.is_operation_document && selectedOperations.length > 0) ||
    !groupedDocuments.is_operation_document;

  const showDocumentValidationButton = groupedDocuments.documents.some(
    (d) =>
      globalEnum.linked_file_status[d.status] === DOCUMENT_STATE_STRING.PENDING
  );

  const customFieldFileAlreadyUploaded =
    groupedDocuments.customfield_id &&
    groupedDocuments.documents.some((doc) => doc.file_url);

  const documentsWithUrl = useMemo(() => {
    const uniqueUrls: string[] = [];

    const groupedDocs = groupedDocuments.documents.map((d) => {
      if (!d.file_url) return undefined;
      if (uniqueUrls.includes(d.file_url)) {
        return undefined;
      }
      const relationIds: number[] = [];
      const sameUrls = groupedDocuments.documents.filter(
        (lf) => lf.file_url === d.file_url
      );

      sameUrls.forEach((u) =>
        u.relation_ids?.forEach((i) => relationIds.push(i))
      );

      uniqueUrls.push(d.file_url);
      return { ...d, relation_ids: relationIds };
    });

    return groupedDocs.filter((d) => d !== undefined) as ILinkedFile[];
  }, [groupedDocuments]);

  const relationType = useMemo(() => {
    if (pathname.includes(t('requests_and_changes.worksites').toLowerCase()))
      return 'worksite';
    return '';
  }, [pathname]);

  const relatedRequests = useMemo(() => {
    const reqs: IRequestTypes[] = [];
    if (listRequestsGroupAll.waiting) {
      listRequestsGroupAll.waiting.forEach((r) => {
        r.demands.forEach((d) => reqs.push(d));
      });
    }
    if (listRequestsGroupAll.todo) {
      listRequestsGroupAll.todo.forEach((r) => {
        r.demands.forEach((d) => reqs.push(d));
      });
    }
    return reqs.filter(
      (r) =>
        r.relation_type === relationType && r.relation_id === Number(relatedId)
    );
  }, [listRequestsGroupAll]);

  const isInstaller = useMemo(() => roleUser === ROLES.PRODUCTION, [roleUser]);

  const status = useMemo(() => {
    // Utilisation de la méthode reduce pour trouver le statut le plus bas
    const lowestStatus = groupedDocuments.documents.reduce(
      (lowest, document) => {
        if (document.status < lowest) {
          return document.status;
        }
        return lowest;
      },
      Infinity
    );
    return globalEnum.linked_file_status[lowestStatus];
  }, [groupedDocuments, roleUser, globalEnum]);

  const canUploadNewFile = useMemo(() => {
    if (importMode) return true;
    if (readOnly) return false;
    if (customFieldFileAlreadyUploaded) {
      return false;
    }
    if (!required) {
      return (
        status === DOCUMENT_STATE_STRING.TO_IMPORT ||
        status === DOCUMENT_STATE_STRING.PENDING
      );
    }
    return status === DOCUMENT_STATE_STRING.TO_IMPORT;
  }, [status]);

  const missingMandatoryFilesCount = useMemo(() => {
    let count = 0;

    groupedDocuments.documents.forEach((doc) => {
      if (doc.mandatory === true && doc.file_url === null) {
        count += 1;
      }
    });

    return count;
  }, [groupedDocuments]);

  const documentIcon = useMemo(() => {
    let left = null;
    let right = null;

    if (readOnly || customFieldFileAlreadyUploaded) {
      if (documentsWithUrl.length > 0) {
        right = {
          icon: <DownloadIcon />,
          color: blueOpx,
        };
      }
    } else if (importMode) {
      right = {
        icon: <AddIcon />,
        color: uploadFileEnabled ? blueOpx : textGrey,
      };
    } else {
      switch (status) {
        case DOCUMENT_STATE_STRING.TO_IMPORT:
          if (!isInstaller) {
            left = {
              icon: <MoreDotsIcon />,
              color: blueOpx,
              dataTestId: 'to_import',
            };
          }
          right = {
            icon: <AddIcon />,
            color: uploadFileEnabled ? blueOpx : textGrey,
          };
          break;
        case DOCUMENT_STATE_STRING.PENDING:
          if (!isInstaller || (isInstaller && required)) {
            left = {
              icon: <ClockAlertOutline />,
              color: orange,
              dataTestId: 'pending',
            };
          }
          if (required) {
            right = { icon: <DownloadIcon />, color: blueOpx };
          } else {
            right = {
              icon: <AddIcon />,
              color: uploadFileEnabled ? blueOpx : textGrey,
            };
          }
          break;
        case DOCUMENT_STATE_STRING.REFUSE:
        case DOCUMENT_STATE_STRING.VALIDATE:
          if (status === DOCUMENT_STATE_STRING.REFUSE) {
            left = { icon: <CrossIcon />, color: red, dataTestId: 'refused' };
          } else {
            left = {
              icon: <CheckIcon />,
              color: green,
              dataTestId: 'validated',
            };
          }
          right = { icon: <DownloadIcon />, color: blueOpx };
          break;
        default:
          break;
      }
    }

    return { left, right };
  }, [status, groupedDocuments, selectedOperations]);

  const getDocumentAnalysis = useCallback(
    async (document: ILinkedFile) => {
      const groupedId = document.ids
        ?.map((id) =>
          groupedDocuments.documents.find((doc) => doc.id === id)
            ?.document_analysis
            ? id
            : false
        )
        .filter((id) => id !== false)
        .pop();
      if (groupedId) {
        const res = await getLinkedFileDocumentAnalysis(groupedId);
        setDocumentReportModalContent(res.data as IDocumentAnalysis);
      } else if (document.id) {
        const res = await getLinkedFileDocumentAnalysis(document.id);
        setDocumentReportModalContent(res.data as IDocumentAnalysis);
      }
    },
    [documentsWithUrl]
  );

  const showConversation = (doc: ILinkedFile) => {
    const relatedList = isInstaller ? listRequests : relatedRequests;

    const request = relatedList.find(
      (req) =>
        req.message === doc.commentary &&
        req.subject === globalEnum.linked_file_type[doc.file_type]
    );

    if (request) {
      updateRequestOrChangeEnumInCard(RequestOrMessageOrChangeEnum.REQUEST);
      updateDetailRequest(request);
    }
  };

  const acceptFileType = () => {
    if (
      groupedDocuments.file_type === fileTypeEnum.PHOTOSAV ||
      groupedDocuments.file_type === fileTypeEnum.PHOTO_APRES_CHANTIER ||
      groupedDocuments.file_type === fileTypeEnum.PHOTO_CALORIFUGEAGE ||
      groupedDocuments.file_type === fileTypeEnum.PHOTO_VANNE_EQUILIBRAGE
    ) {
      return '.jpg,.jpeg,.png';
    }
    if (
      groupedDocuments.file_type === fileTypeEnum.AUTRE ||
      groupedDocuments.file_type === fileTypeEnum.PHOTO_AVANT_CHANTIER ||
      groupedDocuments.file_type === fileTypeEnum.JUSTIFICATIF_IDENTITE ||
      groupedDocuments.file_type === fileTypeEnum.FACTURE ||
      groupedDocuments.file_type === fileTypeEnum.CUSTOM_FIELD
    ) {
      return '.jpg,.jpeg,.png,.pdf';
    }
    return '.pdf';
  };

  const multipleEnabled = acceptFileType().includes('jpg');

  // Fonction pour gérer l'upload d'un fichier individuel
  const uploadOneFile = async (file: File) => {
    if (file.size > MAX_FILE_SIZE) {
      toast.error(
        t('global.file_too_large', { maxFileSize: MAX_FILE_SIZE_MB })
      );
      return;
    }

    if (onUpload) {
      setIsLoading(true);
      if (fileToChange) {
        await onUpload(
          file,
          fileToChange,
          groupedDocuments.customfield_id ?? null
        );
      } else {
        const defaultDocument = {
          ...initialDocument,
          file_type: groupedDocuments.documents[0].file_type,
          mandatory: groupedDocuments.documents[0].mandatory,
          relation_ids:
            groupedDocuments.is_operation_document &&
            selectedOperations.length > 0
              ? selectedOperations.map((operation) => operation.relation_id)
              : groupedDocuments.documents[0].relation_ids,
          relation_type: groupedDocuments.documents[0].relation_type,
          created_by: groupedDocuments.documents[0]?.created_by,
          order: groupedDocuments.documents[0].order,
        };
        await onUpload(
          file,
          defaultDocument,
          groupedDocuments.customfield_id ?? null
        );
      }
    }
  };

  const onChangeInputFile = async (files: File[]) => {
    if (files.length > 0) {
      // Utiliser reduce pour traiter les fichiers de manière séquentielle
      await files.reduce(async (previousPromise, file) => {
        await previousPromise;
        return uploadOneFile(file);
      }, Promise.resolve());

      // Une fois que tous les fichiers sont uploadés
      setIsLoading(false);
      setResetSelect(true);

      // Réinitialiser l'input file
      if (hiddenFileInputCard.current) {
        hiddenFileInputCard.current.value = ''; // réinitialiser la valeur de l'input
      }
    }
  };

  const onClickCard = () => {
    if (canUploadNewFile) {
      if (uploadFileEnabled) handleUploadClick(hiddenFileInputCard);
    }
  };

  const downloadAllFiles = () => {
    groupedDocuments.documents.forEach((document) => {
      const fileUrl = document.file_url?.toString();

      // Vérifier si fileUrl est une chaîne de caractères non vide
      if (typeof fileUrl === 'string' && fileUrl.length > 0) {
        // Vérifier si fileUrl est une URL valide
        try {
          const url = new URL(fileUrl);
          window.open(url.toString());
        } catch (error) {
          console.error(`Invalid URL: ${fileUrl}`);
        }
      }
    });
  };

  const fileIcon = (file: ILinkedFile) => {
    let icon;
    let color;
    if (importMode || readOnly) {
      icon = <FileIcon />;
      color = documentActive.document.id === file.id ? blueOpx : textGrey;
    } else {
      switch (globalEnum.linked_file_status[file.status]) {
        case DOCUMENT_STATE_STRING.PENDING:
          icon = <FileIcon />;
          color = documentActive.document.id === file.id ? blueOpx : textGrey;
          break;
        case DOCUMENT_STATE_STRING.REFUSE:
          icon = <CrossIcon />;
          color = red;
          break;
        case DOCUMENT_STATE_STRING.VALIDATE:
          icon = <CheckIcon />;
          color = green;
          break;
        case DOCUMENT_STATE_STRING.TO_IMPORT:
        default:
          icon = <FileIcon />;
          color = blueOpx;
          break;
      }
    }

    return (
      <ColorCube size="1.5rem" numberOrIcon={icon} color={color} opacity />
    );
  };

  const handleManageSelectedAction = (
    document: ILinkedFile,
    action: 'validate' | 'refuse'
  ) => {
    const existingAction = selectedActions.find(
      (a) => document.id === a.document.id
    );

    if (!existingAction) {
      setSelectedActions((prev) => [...prev, { document, action }]);
    } else {
      const updatedActions = selectedActions.filter(
        (a) => a.document !== document
      );

      if (existingAction.action !== action) {
        setSelectedActions([...updatedActions, { document, action }]);
      } else {
        setSelectedActions([...updatedActions]);
      }
    }
  };

  const handleChangeRejectionComment = (
    document: ILinkedFile,
    rejectionComment: string
  ) => {
    setSelectedActions((prevSelectedActions) => {
      if (prevSelectedActions && prevSelectedActions.length > 0) {
        return prevSelectedActions.map((prevAction) => {
          if (prevAction.document === document) {
            return { ...prevAction, rejectionComment }; // Mettre à jour le commentaire existant
          }
          return prevAction;
        });
      }

      const newAction: ISelectedActionsType = {
        document,
        action: 'refuse',
        rejectionComment,
      };

      return [newAction];
    });
  };

  const handleValidateProcessing = async () => {
    const promises = selectedActions.map((action) => {
      const statusFile = action.action === 'refuse' ? 3 : 4;
      return validateOrRejectDocument(
        Number(action.document.id),
        statusFile,
        updateParentDocuments,
        setIsLoading,
        statusFile === DOCUMENT_STATUS_ENUM.REFUSE
          ? action.rejectionComment
          : undefined
      );
    });

    await Promise.all(promises).then(() => setSelectedActions([]));
  };

  const checkIfCanValidateProcessing = () =>
    !selectedActions ||
    selectedActions.length === 0 ||
    selectedActions.some(
      (action) =>
        action.action === 'refuse' &&
        (!action.rejectionComment || action.rejectionComment === '')
    );

  const changeFileButton = (doc: ILinkedFile) => (
    <ButtonOpx
      label={t('global.document_change')}
      addClass="flex-1"
      small
      onClick={() => {
        setFileToChange(doc);
        handleUploadClick(hiddenFileInputCard);
      }}
    />
  );

  const resetInputFile = () => {
    const inputElement = document.getElementById(
      `inputFile_${index}`
    ) as HTMLInputElement;
    if (inputElement) inputElement.value = ''; // reinitialise la valeur de l'input pour que le onchange fonctionne si on choisi le fichier précedemment uploadé puis supprimé
  };

  useEffect(() => {
    // si supérieur à 2 c'est géré par le input select avec OnChangeValue
    if (groupedDocuments.operations?.length === 1) {
      setSelectedOperations(groupedDocuments.operations);
    }
  }, []);

  useEffect(() => {
    if (resetSelect) {
      setSelectedOperations([]);
      setResetSelect(false);
    }
  }, [resetSelect]);

  return (
    <div>
      {isDocumentReportModal && (
        <ModalDocumentAnalysis
          document={documentReportActiveFile as ILinkedFile}
          content={documentReportModalContent}
          setModal={setDocumentReportModal}
        />
      )}
      <input
        data-test-id={`inputFile_${file_type}${required ? '_required' : ''}`}
        id={`inputFile_${index}`}
        type="file"
        ref={hiddenFileInputCard}
        multiple={multipleEnabled}
        onChange={async (e) => {
          const files = Array.from(e.target.files || []); // Convertir FileList en tableau
          await onChangeInputFile(files);
        }}
        style={{ display: 'none' }}
        accept={acceptFileType()}
      />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="w-full border border-borderGrey rounded-default bg-white"
        data-test-id="button_document_card"
      >
        <button
          type="button"
          onClick={onClickCard}
          className={`w-full flex justify-between items-center p-4 ${
            canUploadNewFile ? '' : 'cursor-default'
          }`}
        >
          <div className="flex space-x-2 items-center">
            {isLoading ? (
              <Loader />
            ) : (
              documentIcon?.left && (
                <ColorCube
                  size="1.5rem"
                  numberOrIcon={documentIcon.left.icon}
                  color={documentIcon.left.color}
                  dataTestId={documentIcon.left.dataTestId}
                  opacity
                />
              )
            )}
            <p className="text-[1rem] text-left">
              {file_type === DOCUMENT_TYPES.AH ? (
                <div className="flex flew-col gap-2">
                  <span>&nbsp;{t('global.document_ah_generated')}</span>
                </div>
              ) : (
                file_type !== DOCUMENT_TYPES.AH && title
              )}
            </p>
          </div>
          <div className="flex space-x-4 items-center">
            {required &&
              (documentsWithUrl.length === 0 ? (
                <p className="text-[.75rem] leading-[.75rem]">
                  {t('global.mandatory')}
                </p>
              ) : (
                missingMandatoryFilesCount > 0 && (
                  <p className="text-[.75rem] leading-[.75rem]">
                    {t('global.missing_file_for_operation', {
                      count: missingMandatoryFilesCount,
                    }) + (missingMandatoryFilesCount > 1 ? 's' : '')}
                  </p>
                )
              ))}
            {documentIcon?.right &&
              (canUploadNewFile ? (
                <ColorCube
                  size="1.5rem"
                  numberOrIcon={documentIcon.right.icon}
                  color={documentIcon.right.color}
                />
              ) : (
                <button type="button" onClick={downloadAllFiles}>
                  <ColorCube
                    size="1.5rem"
                    numberOrIcon={documentIcon.right.icon}
                    color={documentIcon.right.color}
                  />
                </button>
              ))}
          </div>
        </button>
        {canUploadNewFile &&
          groupedDocuments.is_operation_document &&
          groupedDocuments.operations?.length !== 1 && (
            <div className="flex p-4 pt-0 justify-between items-center self-stretch gap-4">
              <div className="text-[.75rem] flex-1">
                {groupedDocuments.operations &&
                groupedDocuments.operations.length < 2
                  ? t('global.operation_concerned')
                  : t('global.operations_concerned')}
              </div>
              {groupedDocuments.operations &&
              groupedDocuments.operations.length < 2 ? (
                <div className="text-[.75rem]">
                  {groupedDocuments.operations[0].name}
                </div>
              ) : (
                <InputSelect
                  addClass="max-w-[20rem] min-w-[20rem]"
                  placeholder={t('global.choose')}
                  dataLabelValue={
                    groupedDocuments.operations
                      ? transformObjectList(
                          groupedDocuments.operations,
                          'name',
                          'relation_id'
                        )
                      : undefined
                  }
                  disabled={
                    groupedDocuments.operations &&
                    groupedDocuments.operations.length < 2
                  }
                  onChangeValue={(value) => {
                    const arrayValue = value as string[];

                    if (groupedDocuments.operations) {
                      const newState = groupedDocuments.operations.filter(
                        (op) => arrayValue.includes(op.name)
                      );

                      setSelectedOperations(newState);
                    }
                  }}
                  isMultipleSelect
                  closeDropdown={closeDropdown}
                  setCloseDropdown={setCloseDropdown}
                  resetValue={resetSelect}
                  dataTestIdSelect={`input_select_operation_concerned${
                    required ? '_required' : ''
                  }`}
                  dataTestIdOptions="input_select_operation_concerned_option"
                />
              )}
            </div>
          )}
        {documentsWithUrl.length > 0 && (
          <div className="w-full flex flex-col gap-4 border-t border-borderGrey p-4">
            {documentsWithUrl.map((doc, i) => {
              const selectedAction = selectedActions?.find((action) => {
                return action.document === doc;
              });

              const documentKey = `${groupedDocuments.title}_${i}_${selectedAction?.action}`;

              const operationsConcerned = groupedDocuments.operations
                ? doc.relation_ids?.map((relationId) => {
                    if (groupedDocuments.operations) {
                      const operation = groupedDocuments.operations.find(
                        (op) => op.relation_id === relationId
                      );
                      return operation;
                    }
                    return undefined;
                  })
                : undefined;

              const groupedDocumentAnalysisAvailable =
                groupedDocuments.documents.some(
                  (document) => document.document_analysis
                );

              return (
                <div key={`document-with-url-${doc.id}`}>
                  {!!updateSignedQuotesToSend &&
                    doc.file_type === DOCUMENT_TYPES.Quote && (
                      <TextWithCheckboxOrToggle
                        label={t('worksites.documents.estimate_message')}
                        onCheck={(isCheck) => {
                          if (isCheck) {
                            updateSignedQuotesToSend((prevState) => [
                              ...prevState,
                              doc,
                            ]);
                          } else {
                            updateSignedQuotesToSend((prevState) =>
                              prevState.filter((d) => d.id !== doc.id)
                            );
                          }
                        }}
                        type="checkbox"
                        checked={signedQuotesToSend.some(
                          (d) => d.id === doc.id
                        )}
                        addClass={`!text-[.75rem] mb-2 ${
                          documentActive.document.id === doc.id
                            ? 'bg-backgroundBody'
                            : ''
                        }`}
                        checkOrTogglePosition="left"
                      />
                    )}
                  <div
                    key={documentKey}
                    className={`flex p-2 flex-col gap-2 self-stretch border border-borderGrey rounded-default ${
                      documentActive &&
                      documentActive.document &&
                      documentActive.document.id &&
                      doc &&
                      doc.id &&
                      documentActive.document.id === doc.id
                        ? 'bg-backgroundBody'
                        : ''
                    }`}
                  >
                    <button
                      type="button"
                      className="flex self-stretch text-[.75rem] justify-between"
                      onClick={() => onSelect(doc)}
                    >
                      {/* doc.file
                    ? doc.file
                    : `${groupedDocuments.title} ${index + 1}` */}
                      <div className="flex items-center gap-2 text-left">
                        {fileIcon(doc)}
                        {shortenStringWithEllipsis(
                          `${
                            doc.file_name ??
                            (doc.file_url && doc.file_url.split('/').pop())
                          }`,
                          50
                        )}
                        {doc.file_type === DOCUMENT_TYPES.AH &&
                          worksiteDetails &&
                          worksiteDetails.convention &&
                          worksiteDetails.convention
                            .is_electronic_signature && (
                            <DocumentStatusTag
                              status={customStatusDoc[doc.status]}
                            />
                          )}
                      </div>
                      <div className="flex gap-2">
                        {showAnalysis &&
                          (doc.document_analysis ||
                            groupedDocumentAnalysisAvailable) && (
                            <ColorCube
                              size="1.5rem"
                              numberOrIcon={<EyeIcon />}
                              color={blueOpx}
                              onClick={async () => {
                                getDocumentAnalysis(doc).then(() => {
                                  setDocumentReportActiveFile(doc);
                                  setDocumentReportModal(true);
                                });
                              }}
                              opacity
                            />
                          )}
                        {(importMode ||
                          (isInstaller &&
                            ((doc.status < DOCUMENT_STATUS_ENUM.PENDING &&
                              doc.mandatory) ||
                              (doc.status < DOCUMENT_STATUS_ENUM.REFUSE &&
                                !doc.mandatory)))) &&
                          !readOnly &&
                          // !doc.mandatory &&
                          onDelete && (
                            <ColorCube
                              size="1.5rem"
                              numberOrIcon={<TrashIcon />}
                              color={red}
                              onClick={async () => {
                                setIsLoading(true);
                                onDelete(doc).then(() => {
                                  resetInputFile(); // reinitialise la valeur de l'input pour que le onchange fonctionne si on choisi le fichier précedemment uploadé puis supprimé
                                  setIsLoading(false);
                                  if (updateSignedQuotesToSend) {
                                    updateSignedQuotesToSend((prevState) =>
                                      prevState.filter((d) => d.id !== doc.id)
                                    );
                                  }
                                });
                              }}
                              opacity
                            />
                          )}
                        {!isInstaller &&
                          doc.status === DOCUMENT_STATUS_ENUM.PENDING &&
                          !importMode && (
                            <div className="flex gap-2">
                              <ColorCube
                                size="1.5rem"
                                numberOrIcon={<CrossIcon />}
                                color={
                                  !selectedAction ||
                                  selectedAction.action === 'refuse'
                                    ? red
                                    : textGrey
                                }
                                opacity
                                onClick={() =>
                                  handleManageSelectedAction(doc, 'refuse')
                                }
                                dataTestId={`refuse_file_${file_type}`}
                              />
                              <ColorCube
                                size="1.5rem"
                                numberOrIcon={<CheckIcon />}
                                color={
                                  !selectedAction ||
                                  selectedAction.action === 'validate'
                                    ? green
                                    : textGrey
                                }
                                opacity
                                onClick={() =>
                                  handleManageSelectedAction(doc, 'validate')
                                }
                              />
                            </div>
                          )}
                      </div>
                    </button>
                    {operationsConcerned && operationsConcerned.length > 0 && (
                      <div className="text-[.75rem] text-textGrey pl-8">
                        {operationsConcerned
                          .map((operation) => operation?.name)
                          .join(', ')}
                      </div>
                    )}
                    {selectedAction?.action === 'refuse' && (
                      <input
                        style={{ borderColor: grey, borderWidth: '1px' }}
                        className="rounded-default w-full p-[.5rem] text-sm"
                        placeholder={t('global.document_input_rejection') || ''}
                        onChange={(e) => {
                          handleChangeRejectionComment(doc, e.target.value);
                        }}
                        readOnly={status === DOCUMENT_STATE_STRING.REFUSE}
                        onClick={(e) => e.stopPropagation()}
                        value={selectedAction?.rejectionComment || ''}
                        onBlur={(e) => e.stopPropagation()}
                        data-test-id={`input_refuse_file_${file_type}`}
                      />
                    )}
                    {doc.status === DOCUMENT_STATUS_ENUM.REFUSE &&
                      !importMode && (
                        <>
                          {isInstaller && (
                            <div className="text-[.875rem] flex p-2 pl-4 pr-2 items-center self-stretch bg-dangerOpacity text-red rounded-default">
                              {doc.commentary}
                            </div>
                          )}
                          <div className="flex gap-2 self-stretch">
                            <ButtonOpx
                              label={
                                isInstaller
                                  ? t('global.send_a_message')
                                  : t('global.document_request')
                              }
                              small
                              type="secondary"
                              addClass="flex-1"
                              onClick={() => showConversation(doc)}
                            />
                            {changeFileButton(doc)}
                          </div>
                        </>
                      )}
                  </div>
                </div>
              );
            })}
            {!isInstaller && showDocumentValidationButton && !importMode && (
              <ButtonOpx
                label={t('global.document_validate_processing')}
                onClick={handleValidateProcessing}
                disabled={checkIfCanValidateProcessing()}
                small
                dataTestId={`button_validate_document_processing_${file_type}`}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export { DocumentCardInList };

DocumentCardInList.defaultProps = {
  onUpload: undefined,
  onDelete: undefined,
  updateParentDocuments: undefined,
  showAnalysis: false,
};
