import { TFunction } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { IWorksiteAddress } from '@models/worksiteCreation/utils/types/worksitesType';
import { IFormAddressDatas } from '@components/formAddress/FormAddress';
import { IKeyStringType } from '../types/globalTypes';

export const customStatusDoc: { [key: number]: string } = {
  1: 'À importer',
  2: 'En attente de validité',
  3: 'Refusé',
  4: 'Validé',
  5: 'À signer',
  6: 'En attente',
  7: 'Signé',
};

export const DOCUMENT_STATE_STRING = {
  TO_IMPORT: 'À importer',
  PENDING: 'En attente de validité',
  REFUSE: 'Refusé',
  VALIDATE: 'Validé',
  TO_SIGN: 'À signer',
  AWAITING_SIGNATURE: 'En attente',
  SIGNED: 'Signé',
};

export const customStatusSignature: { [key: number]: string } = {
  1: 'En attente de signature',
  2: 'Signé',
  3: 'Annulé',
};

export const SIGNATURE_STATE = {
  AWAITING_SIGNATURE: 'En attente de signature',
  SIGNED: 'Signé',
  CANCELED: 'Annulé',
};

export const DOCUMENT_STATUS_ENUM = {
  TO_IMPORT: 1,
  PENDING: 2,
  REFUSE: 3,
  VALIDATE: 4,
  TO_SIGN: 5,
  AWAITING_SIGNATURE: 6,
  SIGNED: 7,
};

export const BUTTONS_DOCUMENT_STATE_TABS = (t: TFunction): IKeyStringType => {
  return {
    REFUSED: t('global.document_refused'),
    ACCEPTED: t('global.document_accepted'),
    REQUEST: t('global.document_request'),
    CHANGE: t('global.document_change'),
    CANCELED: t('global.canceled'),
    VALIDATE_REJECTION: t('global.document_validate_rejection'),
    CONFIRM_DOCUMENT: t('global.document_confirm'),
  };
};
export const getIconAndCharacSize = (
  size: '1.5rem' | '1rem' | '2rem' | '2.5rem'
) => {
  let icon;
  let charac;
  switch (size) {
    case '1rem':
      icon = '.5rem';
      charac = 'text-[.75rem] leading-[.75rem]';
      break;
    case '1.5rem':
      icon = '1rem';
      charac = 'text-[.75rem] leading-[.75rem]';
      break;
    case '2rem':
      icon = '1.5rem';
      charac = 'text-[.875rem] leading-[.875rem]';
      break;
    case '2.5rem':
      icon = '2rem';
      charac = 'text-[1rem] leading-[1rem]';
      break;
    default:
      icon = '1.5rem';
      charac = 'text-[.875rem] leading-[.875rem]';
      break;
  }
  return { icon, charac };
};

export function useOptionalFormContext() {
  let context;

  try {
    context = useFormContext();
  } catch (error) {
    context = null;
  }

  return context;
}

// Utilisez une valeur par défaut de 1.5 Mo si la variable d'environnement n'est pas définie
export const MAX_FILE_SIZE_MB = Number(
  process.env.REACT_APP_MAX_FILE_SIZE || 1.5
);

// Convertissez la taille maximale du fichier en octets pour la comparaison avec la taille du fichier
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

export const INVITATION_STATE = (t: TFunction): IKeyStringType => {
  return {
    PENDING: t('auth.registration.account_completed.invitation_status.pending'),
    DONE: t('auth.registration.account_completed.invitation_status.done'),
    CANCELLED: t(
      'auth.registration.account_completed.invitation_status.cancelled'
    ),
  };
};

export const DIMENSIONS = {
  header_height: '5.5rem',
  requests_and_changes_width: '27.75rem',
  sidebarmenu_open_percent_width: 15,
  sidebarmenu_close_percent_width: 5,
};

export const convertToFormAddressDatas = (
  worksiteAddress: IWorksiteAddress
): IFormAddressDatas => ({
  street: worksiteAddress.streetName,
  streetNumber: worksiteAddress.numberStreet,
  zipCode: worksiteAddress.zipCode,
  city: worksiteAddress.city,
  country: worksiteAddress.country,
  latitude: worksiteAddress.latitude,
  longitude: worksiteAddress.longitude,
});

export const transformAddressKeys = (
  datas: Partial<IFormAddressDatas>
): Partial<IWorksiteAddress> => {
  const transformed: Partial<IWorksiteAddress> = {};

  if (datas.city !== undefined) transformed.city = datas.city;
  if (datas.country !== undefined) transformed.country = datas.country;
  if (datas.street !== undefined) transformed.streetName = datas.street;
  if (datas.streetNumber !== undefined)
    transformed.numberStreet = datas.streetNumber;
  if (datas.zipCode !== undefined) transformed.zipCode = datas.zipCode;
  if (datas.latitude !== undefined) transformed.latitude = datas.latitude;
  if (datas.longitude !== undefined) transformed.longitude = datas.longitude;

  return transformed;
};

export const DOCUMENT_TYPES = {
  AH: 1,
  CompletionCertificate: 2,
  ContributionFrame: 3,
  Quote: 4,
  Invoice: 5,
  AfterServicePhoto: 6,
  IndivisionAH: 7,
  TaxNotice: 8,
  OwnershipProof: 9,
  LandlordAH: 10,
  GrantNotification: 11,
  BeforeWorkPhoto: 12,
  TechnicalSheet: 13,
  EnergyAuditReport: 14,
  SizingNote: 15,
  Other: 16,
  Contract: 17,
  Agreement: 18,
  CofracReport: 19,
  CofracSummary: 20,
  AfterWorkPhoto: 21,
  SignedQuote: 22,
  InsulationPhoto: 23,
  BalancingValvePhoto: 24,
  SubcontractorDoc: 25,
  RGE: 26,
  AuditSummary: 27,
  AuditReport: 28,
  CofracAuditReport: 29,
  ExternalAgreement: 30,
  Annexes: 31,
  PartnershipContract: 32,
  PhotoHorodatee: 33,
  SignedAH: 100,
  KBIS: 101,
  ManagerID: 102,
  RCPro: 103,
  SocialRegularityCertificate: 104,
  DecennialInsurance: 105,
  MandateContract: 1701,
  Pouvoir: 106,
};

export const InputTypes = {
  TEXT: 1,
  NUMBER: 2,
  BOOL: 3,
  DATE: 4,
  SELECT: 5,
  MULTIPLE_SELECT: 6,
  EMAIL: 7,
  ADDRESS: 8,
  PHONE: 9,
  IMAGE: 10,
  MULTIPLE_IMAGE: 11,
  DOCUMENT: 12,
  MULTIPLE_DOCUMENT: 13,
} as const;

export const InputTypeReverseLookup: {
  [key: number]: keyof typeof InputTypes;
} = {
  1: 'TEXT',
  2: 'NUMBER',
  3: 'BOOL',
  4: 'DATE',
  5: 'SELECT',
  6: 'MULTIPLE_SELECT',
  7: 'EMAIL',
  8: 'ADDRESS',
  9: 'PHONE',
  10: 'IMAGE',
  11: 'MULTIPLE_IMAGE',
  12: 'DOCUMENT',
  13: 'MULTIPLE_DOCUMENT',
} as const;

export const USER_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
  PENDING: 3,
};
