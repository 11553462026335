import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IColumn, IRow, ListTable } from '@components/ListTable';
import { createColumnHelper } from '@tanstack/react-table';
import {
  deleteBrand,
  deleteMaterial,
  getAllMaterialsByBrandId,
  updateMaterial,
} from '@models/materials/apiRequests/materialsRequests';
import { HeaderContext } from '@context/headerContext';
import { AddFatIcon, TrashIcon } from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputText } from '@components/atomic/inputs/InputText';
import { FormProvider, useForm } from 'react-hook-form';
import { red } from '@assets/color';
import { ColorCube } from '@components/atomic/ColorCube';
import { ModalAddProduct } from '@models/materials/components/ModalAddProduct';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteBrandModal } from '@models/Brand/DeleteBrandModal';
import { DeleteMaterialModal } from '@models/Brand/DeleteMaterialModal';
import { IMaterial } from '@models/materials/utils/materialTypes';
import { SETTINGS_ROUTES } from '@utils/routesUrls';

function BrandsMaterials() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [materials, setMaterials] = useState<IMaterial[]>([]);
  const [materialName, setMaterialName] = useState<string>('');
  const [selectedMaterial, setSelectedMaterial] = useState<IMaterial | null>(
    null
  );
  const [showDeleteBrandModal, setShowDeleteBrandModal] =
    useState<boolean>(false);
  const [showDeleteMaterialModal, setShowDeleteMaterialModal] =
    useState<boolean>(false);
  const [brandData, setBrandData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const methods = useForm();
  // const navigate = useNavigate();
  const [showNewProductModal, setShowNewProductModal] =
    useState<boolean>(false);

  // const [brandData, setBrandData] = useState<any>(null);

  const refetchProducts = async () => {
    if (id) {
      getAllMaterialsByBrandId(parseInt(id, 10), t).then((r) => {
        setBrandData(r);
        setMaterials(r.materials);
        updateTitleHeader(r.name);
        refreshHeader(true);
      });
    }
  };

  useEffect(() => {
    refetchProducts();
  }, []);

  const columnHelper = createColumnHelper<IMaterial>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: () => 'id',
      }),
      columnHelper.accessor('name', {
        header: () => t('brand.products.own'),
      }),
    ],
    [materials]
  );

  const handleUpdateMaterial = async () => {
    if (selectedMaterial) {
      const updatedMaterial = { ...selectedMaterial, name: materialName };
      const res = await updateMaterial(updatedMaterial, t);
      if (res) {
        const newMaterials = materials.map((material) =>
          material.id === updatedMaterial.id ? updatedMaterial : material
        );
        setMaterials(newMaterials);
        setSelectedMaterial(updatedMaterial);
        setMaterialName(updatedMaterial.name);
      }
    }
  };

  const handleDeleteMaterial = async () => {
    if (selectedMaterial) {
      const res = await deleteMaterial(selectedMaterial, t);

      if (res) {
        const newMaterials = materials.filter(
          (material) => material.id !== selectedMaterial.id
        );
        setMaterials(newMaterials);
        setSelectedMaterial(null);
        setMaterialName('');
      }
    }
  };

  const handleDeleteBrand = async () => {
    if (id != null) {
      setIsLoading(true);
      await deleteBrand(parseInt(id, 10), t);
      setIsLoading(false);
      navigate(SETTINGS_ROUTES.SETTINGS);
    }
  };

  if (materials?.length === 0) {
    return (
      <div className="w-full flex flex-col pt-6">
        <div className="flex flex-col lg:flex-row-reverse gap-2">
          <ColorCube
            size="2.5rem"
            numberOrIcon={<TrashIcon />}
            color={red}
            opacity
            onClick={() => setShowDeleteBrandModal(true)}
          />
          <ButtonOpx
            label={t('brand.products.new')}
            icon={<AddFatIcon />}
            type="primary"
            onClick={() => setShowNewProductModal(true)}
          />
        </div>
        <div className="list_noElements flex items-center justify-center h-36 text-danger">
          {t('list.no_result')}
        </div>
        {showNewProductModal && id && (
          <ModalAddProduct
            brand_id={parseInt(id, 10)}
            setShowModal={setShowNewProductModal}
            refetch={refetchProducts}
          />
        )}
        {showDeleteBrandModal && (
          <DeleteBrandModal
            setShowModal={setShowDeleteBrandModal}
            brandData={brandData}
            deleteBrand={handleDeleteBrand}
            isLoading={isLoading}
          />
        )}
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col pt-6 h-full">
      <div className="flex flex-row-reverse items-center py-[1.5rem] gap-5">
        <ColorCube
          size="2.5rem"
          numberOrIcon={<TrashIcon />}
          color={red}
          opacity
          onClick={() => setShowDeleteBrandModal(true)}
        />
        <ButtonOpx
          label={t('brand.products.new')}
          icon={<AddFatIcon />}
          type="primary"
          onClick={() => setShowNewProductModal(true)}
        />
      </div>
      <div className="w-full flex flex-col lg:flex-row lg:gap-6 pt-6 h-full">
        <div className={`px-6 w-full ${selectedMaterial ? 'lg:w-1/3' : ' '}`}>
          <div className="w-full flex flex-col text-neutral-400 h-full">
            {materials?.length > 0 && (
              <ListTable
                loading={false}
                columns={columns as IColumn[]}
                data={materials}
                callBackOnRowClick={(e: IRow) => {
                  setSelectedMaterial(e.original as IMaterial);
                  setMaterialName((e.original as IMaterial).name);
                }}
                addClass="w-full"
                hiddenColumns={['id']}
              />
            )}
          </div>
        </div>
        {selectedMaterial && (
          <div className="border border-borderGrey rounded-default flex flex-col w-full lg:w-2/3 bg-white p-[1.5rem] h-full">
            <div className="flex flex-row justify-between items-center pb-[1.5rem] border-b-borderGrey">
              <p className="text-[1.5rem] font-medium">
                {`${t('brand.products.edit')} ${selectedMaterial.name}`}
              </p>
              <div className="flex gap-5">
                <ButtonOpx
                  label={t('brand.products.update')}
                  type="primary"
                  onClick={handleUpdateMaterial}
                />
                <ColorCube
                  size="2.5rem"
                  numberOrIcon={<TrashIcon />}
                  color={red}
                  opacity
                  onClick={() => setShowDeleteMaterialModal(true)}
                />
              </div>
            </div>
            <FormProvider {...methods}>
              <form>
                <InputText
                  id="materialName"
                  name="materialName"
                  type="text"
                  value={materialName}
                  defaultValue={selectedMaterial.name}
                  onChange={(value) => setMaterialName(value as string)}
                  placeholder={t('brand.products.name')}
                  required
                />
              </form>
            </FormProvider>
          </div>
        )}
      </div>
      {showNewProductModal && id && (
        <ModalAddProduct
          brand_id={parseInt(id, 10)}
          setShowModal={setShowNewProductModal}
          refetch={refetchProducts}
        />
      )}
      {showDeleteBrandModal && (
        <DeleteBrandModal
          setShowModal={setShowDeleteBrandModal}
          brandData={brandData}
          deleteBrand={handleDeleteBrand}
          isLoading={isLoading}
        />
      )}

      {showDeleteMaterialModal && (
        <DeleteMaterialModal
          setShowModal={setShowDeleteMaterialModal}
          materialData={selectedMaterial}
          deleteMaterial={handleDeleteMaterial}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export { BrandsMaterials };
