/* eslint-disable react/no-unstable-nested-components */
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { GlobalContext } from '@context/globalContext';
import { HeaderContext } from '@context/headerContext';
import { SubHeader } from '@components/subHeader/SubHeader';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { getContractList } from '@models/contracts/apiRequests/contractRequests';
import { AuthContext } from '@context/authContext';
import { ListTable, IColumn, IRow } from '@components/ListTable';
import {
  getColumns,
  noPermissions,
  verifyPermission,
} from '@models/contracts/utils/contractHelper';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import {
  IContractType,
  IMenuType,
} from '@models/contracts/utils/contractTypes';

import {
  GERER,
  LISTER,
  URL_TYPES,
} from '@models/contracts/utils/contractConstants';
import { isInstaller, ROLES } from '@utils/roles';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { getUserByToken } from '@models/auth/apiRequests/authRequests';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import {
  FilterRequestData,
  ISortAndFilterType,
} from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  resetPagination,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import {
  ContractTypes,
  ConventionModelStatus,
} from '@models/contractCreation/utils/enums';
import { ContractStatusEnum } from '@models/contracts/utils/enums';
import {
  CONTRACTS_ROUTES,
  CONTRACTS_ROUTES_WITH_ID,
  CONTRACTS_ROUTES_WITH_PARAMS,
} from '@utils/routesUrls';
import { FilterCriteriaEnum } from '@utils/enums';
import { ResultsPerPageButton } from '@components/atomic/pagination/ResultsPerPageButton';
import { blueOpx } from '@assets/color';

interface IWorksitesProps {
  adminMode?: boolean;
}

function Contracts({ adminMode }: IWorksitesProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const { roleUser, globalEnum } = useContext(GlobalContext);
  const { updateTitleHeader, refreshHeader, updateTagHeader } =
    useContext(HeaderContext);
  const { user, updateUser } = useContext(AuthContext);

  const preFilter = useMemo((): FilterRequestData[] => {
    const filterData: FilterRequestData[] = [];
    const company = query.get('company');
    if (company) {
      filterData.push({
        criteria: FilterCriteriaEnum.COMPANY_NAME,
        ids: [parseInt(company as string, 10)],
      });
    }
    return filterData;
  }, [query]);

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);
  const [resultsPerPage, setResultsPerPage] = useState<number>();
  const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>({ filters: preFilter });
  const [currentPage, setCurrentPage] = useState<number>(1); // Ajouter un état pour la page actuelle

  const [tabs, setTabs] = useState<string[]>([]);
  const [menus, setMenus] = useState<IMenuType[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<IMenuType>();

  const isDraft = useMemo(() => {
    return selectedMenu?.filter === ContractStatusEnum.EN_CREATION;
  }, [selectedMenu]);
  const isModel = useMemo(() => {
    return selectedMenu?.filter === ContractStatusEnum.MODEL;
  }, [selectedMenu]);

  const getDataList = async (page: number, menu: IMenuType | undefined) => {
    setIsLoading(true);
    const filter = Number(menu?.filter || ContractStatusEnum.EN_COURS);
    const resList = await getContractList(
      page,
      resultsPerPage || 5,
      filter,
      sortAndFilterData,
      adminMode
    );

    if (resList) {
      const tmpData = resList.data.map((contract: IContractType) => {
        return {
          ...contract,
          sum_volume_ops_classique_kwhc:
            contract.convention_worksites?.classique || 0,
          sum_volume_ops_precaire_kwhc:
            contract.convention_worksites?.precaire || 0,
        };
      });

      setData(tmpData);

      const newPaginationData = {
        current_page: resList.current_page,
        total: resList.total,
        per_page: resList.per_page,
        last_page: resList.last_page,
        next_page_url: resList.next_page_url,
      };
      setPaginationData(newPaginationData);

      if (resList.current_page > pagesDatas.length) {
        setPagesDatas([
          ...pagesDatas,
          { data: resList.data, meta: newPaginationData },
        ]);
      }
      setIsLoading(false);
    }
  };

  const handleChangeSelectedMenu = async (menu: IMenuType) => {
    setSelectedMenu(menu);
    setCurrentPage(1);
    resetPagination(setPaginationData, setPagesDatas);
    await getDataList(1, menu);
  };

  const getTabList = async () => {
    const newTabs = Object.values(globalEnum.contract_status_ui);
    const newMenus = Object.entries(globalEnum.contract_status_ui).map((m) => ({
      label: m[1],
      filter: Number(m[0]),
    }));

    setTabs(newTabs);
    setMenus(newMenus || []);
    handleChangeSelectedMenu(newMenus[0]);
  };

  const columns = useMemo(
    () => getColumns(t, roleUser, globalEnum.contract_type, globalEnum),
    [data, roleUser, menus, globalEnum]
  );
  const columnsMemo = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => <LoaderSkeleton height="1.5rem" />,
          }))
        : columns,
    [isLoading, columns]
  );

  const hiddenColumns = useMemo(() => {
    let hidden =
      roleUser === ROLES.PRODUCTION
        ? [
            'id',
            'company_name',
            'volume_classique_kwhc',
            'volume_precaire_kwhc',
            'convention_model_status',
            'oblige',
            'related_contract',
            'active_installer_count',
          ]
        : [
            'id',
            'convention_model_status',
            'oblige',
            'related_contract',
            'active_installer_count',
          ];

    if (selectedMenu && selectedMenu.filter === ContractStatusEnum.EN_CREATION)
      hidden = [...hidden, 'end_date', 'start_date'];

    if (selectedMenu && selectedMenu.filter === ContractStatusEnum.MODEL) {
      hidden = ['id', 'company_name', 'start_date'];
    }

    return hidden;
  }, [roleUser, selectedMenu]);

  if (user && !verifyPermission(user, [LISTER.CONVENTION, LISTER.CONTRACT])) {
    return noPermissions(t);
  }

  const pageAlreadyLoad = (page: number) => {
    if (pagesDatas[page - 1]) {
      setData(pagesDatas[page - 1].data);
      setPaginationData(pagesDatas[page - 1].meta);
    } else {
      getDataList(page, selectedMenu);
    }
  };

  const getActiveButton = () => {
    if (tabs && tabs[0]) return tabs[0];
    return '';
  };

  useEffect(() => {
    refreshHeader();
    updateTitleHeader(`${t('sidebar.contracts')}`);
    updateTagHeader(undefined);
    getUserByToken(updateUser);
    getTabList();
  }, []);

  useEffect(() => {
    if (sortAndFilterData || resultsPerPage) {
      resetPagination(setPaginationData, setPagesDatas);
      getDataList(1, selectedMenu);
    }
  }, [sortAndFilterData, resultsPerPage]);

  return (
    <div className="w-full items-center justify-center">
      <SubHeader
        leftPart={
          roleUser === ROLES.PRODUCTION ? (
            <div />
          ) : (
            <TabsLeftPart
              titlesList={tabs || ['']}
              onClick={(title) => {
                const tabMenu = menus?.filter(
                  (menu) => menu.label === title
                )[0];
                handleChangeSelectedMenu(tabMenu);
              }}
              activeButton={getActiveButton()}
            />
          )
        }
        rightPart={
          <>
            <SortAndFilter
              page={adminMode ? 'ADMIN_CONTRATS' : 'CONTRATS'}
              onSort={(column, direction) =>
                sortList(column, direction, setSortAndFilterData)
              }
              hideFilter={!adminMode}
              onFilter={(filters) => {
                filterList(filters, setSortAndFilterData);
              }}
              preFilter={preFilter}
            />
            {roleUser !== ROLES.PRODUCTION &&
              !isInstaller(user) &&
              verifyPermission(user, [
                GERER.CONTRACT,
                GERER.CONTRACT_VENTE,
                GERER.CONVENTION,
              ]) &&
              selectedMenu?.filter !== ContractStatusEnum.MODEL &&
              !adminMode && (
                <ButtonOpx
                  onClick={() => navigate(CONTRACTS_ROUTES.CONTRACTS_CREATION)}
                  type="primary"
                  label={t('contract.new_contract')}
                  dataTestId="new_contract"
                />
              )}
            {selectedMenu?.filter === ContractStatusEnum.MODEL && (
              <ButtonOpx
                onClick={() => navigate(CONTRACTS_ROUTES.CONVENTION_MODEL)}
                type="primary"
                label={t('contract.new_template')}
                dataTestId="new_template"
              />
            )}
          </>
        }
      />
      <div>
        <div className="flex justify-between">
          <div className="mt-4">
            {paginationData?.total !== undefined && (
              <div>
                {t('pagination.total_results')} : {paginationData.total}
              </div>
            )}
          </div>
          <div className="mb-4">
            <ResultsPerPageButton
              resultsPerPage={resultsPerPage || 5}
              onChange={(value) => {
                setResultsPerPage(value);
                setCurrentPage(1);
                getDataList(1, selectedMenu);
              }}
              options={[5, 10, 20, 50, 100, 250]}
              colorPagination={blueOpx}
            />
          </div>
        </div>
        <ListTable
          columns={columnsMemo as IColumn[]}
          data={data}
          callBackOnRowClick={async (e: IRow) => {
            const rowData: IContractType = e.original as IContractType;

            let navigateUrl = '';

            switch (rowData.contract_type) {
              case ContractTypes.CONVENTION:
                navigateUrl = `/${URL_TYPES.CONVENTION}/${rowData.id}`;
                break;
              case ContractTypes.TRIPARTITE:
                navigateUrl = `/${URL_TYPES.TRIPARTITE}/${rowData.id}`;
                break;
              case ContractTypes.MANDAT:
                navigateUrl = CONTRACTS_ROUTES_WITH_PARAMS(
                  URL_TYPES.MANDANT,
                  rowData.id
                ).CONTRACT_VIEW;
                break;
              case ContractTypes.MANDAT_NON_DEPOSANT:
                navigateUrl = CONTRACTS_ROUTES_WITH_PARAMS(
                  URL_TYPES.MANDANT_NON_DEPOSANT,
                  rowData.id
                ).CONTRACT_VIEW;
                break;
              case ContractTypes.VENTE:
              case ContractTypes.DELEGATION:
                navigateUrl = CONTRACTS_ROUTES_WITH_PARAMS(
                  URL_TYPES.SALE,
                  rowData.id
                ).CONTRACT_VIEW;
                break;
              default:
                break;
            }

            if (isDraft) {
              navigate(CONTRACTS_ROUTES_WITH_ID(rowData.id).CONTRACTS_EDIT);
            } else if (isModel) {
              navigateUrl =
                rowData?.convention_model_status ===
                ConventionModelStatus.IN_PROGRESS
                  ? CONTRACTS_ROUTES_WITH_ID(rowData.id).CONVENTION_MODEL_EDIT
                  : CONTRACTS_ROUTES_WITH_ID(rowData.id).CONVENTION_MODEL;
              navigate(navigateUrl);
            } else navigate(navigateUrl);
          }}
          hiddenColumns={hiddenColumns}
          loading={isLoading}
          rowsPerPage={resultsPerPage}
        />
        <ListPagination
          getData={(page) => {
            setCurrentPage(page);
            if (page > pagesDatas.length) {
              getDataList(page, selectedMenu);
            } else {
              pageAlreadyLoad(page);
            }
          }}
          paginationData={paginationData}
          resultsPerPage={resultsPerPage || 5}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
}

Contracts.defaultProps = {
  adminMode: false,
};

export { Contracts };
