import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isBefore, parse } from 'date-fns';

import { GlobalContext } from '@context/globalContext';
import { AuthContext } from '@context/authContext';

import { DocumentViewer } from '@components/documents/DocumentViewer';
import { CardInformations } from '@components/informations/CardInformations';

import { getContacts } from '@models/users/apiRequests/userRequests';

import {
  getCardInfosGeneralConvention,
  getInfos,
  handleUpload,
} from '@models/conventions/utils/conventionHelper';

import { IConventionType } from '@models/conventions/utils/conventionTypes';

import { ENTITY_TYPES, isInstaller, ROLES } from '@utils/roles';

import { iconBlue, lightBlue, red } from '@assets/color';
import { initDocumentActive } from '@utils/initialState';

import { convertKiloToMega, mergePdfFiles } from '@utils/functions';
import { fetchBlobs } from '@apiRequests/globalRequests';
import { KpiTab } from '@components/atomic/KpiTab';
import { IKPITab, ILinkedFile } from '@../../types/globalTypes';
import { WorksiteIcon } from '@assets/images/svgComponents';
import {
  BeneficiaryTypes,
  ContractTypes,
} from '@models/contractCreation/utils/enums';
import { InternalReferents } from '@models/contracts/components/InternalReferents';
import { IUserType } from '@models/auth/utils/types';
import { UploadButton } from '@components/documents/UploadButton';

interface ITabInfosConventionsProps {
  convention: IConventionType;
  refresh: () => void;
}

function TabInfosConventions(props: ITabInfosConventionsProps) {
  const { convention, refresh } = props;
  const { t } = useTranslation();
  const { roleUser, globalEnum, updateDocumentActive, userView } =
    useContext(GlobalContext);
  const { user } = useContext(AuthContext);

  const [contacts, setContacts] = useState<IUserType[]>([]);
  const [loading, setLoading] = useState(false);

  const getUserContacts = async () => {
    setLoading(true);
    const response = await getContacts();
    setContacts(response);
    setLoading(false);
  };

  const isCreator = useMemo(() => {
    return userView?.entity_id === convention.from.id;
  }, [userView, convention]);

  const itemsInfoGeneral = useMemo(() => {
    return getCardInfosGeneralConvention(t, convention, globalEnum);
  }, [t, convention]);

  const isExpired = useMemo(() => {
    if (!convention) return true;

    const end_date = parse(convention.end_date, 'yyyy-MM-dd', new Date());
    return isBefore(end_date, new Date());
  }, [convention]);

  const itemsInfoInstaller = useMemo(() => {
    return getInfos(
      t,
      convention.installer,
      convention.contactInstaller,
      { typeContact1: 1, typeContactSign: 2 },
      (globalEnum as any).signature_status,
      true
    );
  }, [t, convention, roleUser, isExpired]);

  const itemsInfoBeneficiary = useMemo(() => {
    const isB2C =
      convention.beneficiaries_type === BeneficiaryTypes.PERSON &&
      !!convention.beneficiary;
    return getInfos(
      t,
      convention.beneficiary,
      convention.contactBeneficiary,
      { typeContact1: 1, typeContactSign: 2 },
      (globalEnum as any).signature_status,
      true,
      true,
      isB2C
    );
  }, [t, convention, roleUser, isExpired]);

  const itemsInfoCreator = useMemo(() => {
    return getInfos(
      t,
      convention.from,
      convention.contactPerso,
      { typeContact1: 3, typeContactSign: 2 },
      (globalEnum as any).signature_status,
      true
    );
  }, [t, convention, roleUser, isExpired]);

  const itemsInfoAmo = useMemo(() => {
    return getInfos(
      t,
      convention.amo,
      convention.contactAmo,
      { typeContact1: 1, typeContactSign: 2 },
      (globalEnum as any).signature_status,
      true
    );
  }, [t, convention, roleUser, isExpired]);

  const itemsInfoIntermediary = useMemo(() => {
    return getInfos(
      t,
      convention.intermediary_business,
      convention.contactIntermediaryBusiness,
      { typeContact1: 1, typeContactSign: 2 },
      (globalEnum as any).signature_status,
      true
    );
  }, [t, convention, roleUser, isExpired]);

  const userContacts = useMemo(() => {
    switch (userView?.entity_id) {
      case convention.from.id:
        return convention.contactPerso;
      case convention.amo?.id:
        return convention.contactAmo;
      case convention.installer.id:
        return convention.contactInstaller;
      case convention.beneficiary?.id:
        return convention.contactBeneficiary;
      default:
        return [];
    }
  }, [userView, convention]);

  const gisementClassique =
    convention?.worksitesCreatedTotalKwhcClassic || null;
  const gisementPrecaire =
    convention?.worksitesCreatedTotalKwhcPrecaire || null;

  const vTotalClassic = Number(convention.volume_classique_kwhc);
  const vTotalPrecaire = Number(convention.volume_precaire_kwhc);

  const ratioClassic =
    vTotalClassic > 0 ? Number(gisementClassique) / vTotalClassic : 0;
  const ratioPrecaire =
    vTotalPrecaire > 0 ? Number(gisementPrecaire) / vTotalPrecaire : 0;

  const kpiTabContracts: IKPITab[] = [
    {
      infos: [
        {
          title: t('convention.tab_info_general.KPITabs.nb_worksites'),
          subtitle: `${convention.worksitesCreatedCount || '0'}`,
        },
      ],
      icon: <WorksiteIcon />,
      colorIcon: iconBlue,
      dataTestId: 'nb_worksites',
    },
    {
      infos: [
        {
          title: t('global.KPITabs.volume_cee_classique'),
          subtitle: `${convertKiloToMega(
            gisementClassique
          )} / ${convertKiloToMega(vTotalClassic)} MWhc`,
        },
      ],
      colorIcon: '#46BDE2',
      progressBar: true,
      ratios: ratioClassic,
    },
    {
      infos: [
        {
          title: t('global.KPITabs.volume_cee_precaire'),
          subtitle: `${convertKiloToMega(
            gisementPrecaire
          )} / ${convertKiloToMega(vTotalPrecaire)} MWhc`,
        },
      ],
      colorIcon: '#916BE2',
      progressBar: true,
      ratios: ratioPrecaire,
    },
  ];

  useEffect(() => {
    getUserContacts();
  }, []);

  useEffect(() => {
    const partenaire = isInstaller(user)
      ? convention.from
      : convention.installer;

    const list = `${t('convention.title')} ${partenaire.company_name || ''}`;
    if (convention.linkedFiles) {
      if (convention.linkedFiles.length === 1) {
        updateDocumentActive({
          ...initDocumentActive,
          list,
          listAsTitle: true,
          document: convention.linkedFiles[0],
        });
      } else if (convention.linkedFiles?.length > 0) {
        fetchBlobs(convention.linkedFiles).then((blobs) => {
          if (blobs && blobs.length > 0) {
            mergePdfFiles(blobs, list, updateDocumentActive);
          } else {
            updateDocumentActive({
              ...initDocumentActive,
              list,
              listAsTitle: true,
            });
          }
        });
      }
    }
  }, [convention, isInstaller, user]);

  const handleUploadFile = async (file: File, document: ILinkedFile) => {
    await handleUpload(file, document, refresh);
  };

  const renderInfosGeneral = () => {
    const progressStatus = convention.progress_status;
    return (
      <CardInformations
        title={t('convention.general_information') || ''}
        data={{
          status: {
            color: [1, 3].includes(progressStatus) ? red : lightBlue,
            label: globalEnum.contract_progress_status[`${progressStatus}`],
          },
          rows: itemsInfoGeneral,
        }}
        loading={loading}
        dataTestId="informations_card"
      />
    );
  };

  const renderInfosReferents = () => {
    return (
      <InternalReferents
        contacts={userContacts || []}
        list={contacts}
        contractId={convention.id}
        contractType={
          convention.beneficiary
            ? ContractTypes.TRIPARTITE
            : ContractTypes.CONVENTION
        }
        onRefresh={refresh}
        isConvention
        isCreator={isCreator}
      />
    );
  };

  const renderInfosAmo = () => {
    return (
      <CardInformations
        title={`${t('contract.amo_infos')}`}
        data={{
          status: undefined,
          rows: itemsInfoAmo,
        }}
        loading={loading}
      />
    );
  };

  const renderInfosIntermediary = () => {
    return (
      <CardInformations
        title={`${t('contract.business.tab_title')}`}
        data={{
          status: undefined,
          rows: itemsInfoIntermediary,
        }}
        loading={loading}
      />
    );
  };

  const renderInfosPartner = () => {
    let rows = convention.beneficiary
      ? itemsInfoBeneficiary
      : itemsInfoInstaller;

    let title = convention.beneficiary
      ? t('convention.beneficiary_information')
      : t('convention.installer_information');

    if (!isCreator) {
      rows = itemsInfoCreator;
      title = `${t('convention.partner_information')} ${
        convention.from.company_name || ''
      }`;
    }

    return (
      <CardInformations
        title={title}
        data={{
          status: undefined,
          rows,
        }}
        loading={loading}
      />
    );
  };

  if (!convention) return null;
  return (
    <div className="w-full flex flex-wrap">
      <div className="w-full md:w-[60%] pr-6 grid grid-cols-1 gap-y-6">
        <KpiTab infos={kpiTabContracts} />
        {renderInfosGeneral()}
        {roleUser === ROLES.PRODUCTION && (
          <>
            {renderInfosPartner()}
            {convention.beneficiary &&
              convention.amo?.id !== userView?.entity_id &&
              renderInfosAmo()}
            {convention.intermediary_business && renderInfosIntermediary()}
            {renderInfosReferents()}
          </>
        )}
        {roleUser !== ROLES.PRODUCTION && (
          <>
            {renderInfosPartner()}
            {convention.beneficiary &&
              convention.amo?.id !== userView?.entity_id &&
              renderInfosAmo()}
            {convention.intermediary_business && renderInfosIntermediary()}
            {renderInfosReferents()}
          </>
        )}
      </div>

      <div className="w-full md:w-[40%] flex flex-col gap-5">
        {user &&
          user.current_entity_type &&
          [
            ENTITY_TYPES.MANDATAIRE,
            ENTITY_TYPES.MANDATAIRE_NON_DEPOSANT,
            ENTITY_TYPES.OBLIGE,
            ENTITY_TYPES.DELEGATAIRE,
          ].includes(user.current_entity_type) && (
            <UploadButton
              fileType={16}
              name={
                convention.linkedFiles?.length
                  ? t('contract.document.update')
                  : t('contract.document.import')
              }
              onUpload={handleUploadFile}
              document={
                convention.linkedFiles?.length
                  ? convention.linkedFiles[0]
                  : {
                      id: null,
                      relation_ids: [convention.id],
                      relation_type: 'convention',
                      file_type: 16,
                      status: 1,
                      isolate_file: false,
                      file_url: null,
                      file_name: null,
                      linked_entity_id: null,
                      commentary: null,
                      file: null,
                      created_at: null,
                      uploaded_at: null,
                      is_deletable: null,
                      file_hash: null, //
                    }
              }
            />
          )}
        <DocumentViewer isLoading={loading} />
      </div>
    </div>
  );
}

export { TabInfosConventions };
