/* eslint-disable consistent-return */
// RECUPER LES CORRESPONDANCES POUR LES ID
import { Dispatch, SetStateAction } from 'react';
import { axiosClient } from '@api/apiClient';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { IWorksite } from '@models/worksiteCreation/utils/types/worksitesType';
import { initDocumentActive } from '@utils/initialState';
import {
  urlGetFilters,
  urlGetGlobalEnum,
  urlLinkedFileAction,
  urlPostGlobalSearch,
  urlPostLinkedFiles,
  urlPostWorksiteLinkedFiles,
  urlPostRegistrationLinkedFiles,
  urlDeleteRegistrationLinkedFiles,
  urlGetLinkedFileArchives,
  urlGetLinkedFileDocumentAnalysis,
  urlGetExportCsvDocumentAnalysis,
  urlGetCheckLinkedFileSignature,
} from '@api/apiUrls';
import { SortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  IActiveDocument,
  IGlobalEnumType,
  ILinkedFile,
} from '../types/globalTypes';

export const getGlobalEnum = async (
  setGlobalEnum: Dispatch<SetStateAction<IGlobalEnumType>>
) => {
  try {
    const response = await axiosClient.get(urlGetGlobalEnum);
    setGlobalEnum(response.data);
  } catch (error) {
    toast.error(error.message);
  }
};

// ENVOI DE DOCUMENTS
export const sendLinkedFile = async (
  file: File,
  relation_ids: number[],
  relation_type: string,
  file_type: number,
  status: number,
  commentary: string | null,
  linked_entity_id: number | null,
  mandatory: boolean,
  documentId?: number,
  updateParentDocuments?: () => Promise<void> | undefined,
  customfield_id?: number
) => {
  const formData = new FormData();
  if (documentId) {
    formData.append('id', documentId.toString());
  }
  formData.append('file', file);
  relation_ids.forEach((id) => {
    formData.append('relation_ids[]', id.toString());
  });
  formData.append('relation_type', relation_type);
  formData.append('file_type', file_type.toString());
  formData.append('status', status.toString());
  formData.append('linked_entity_id', String(linked_entity_id || ''));
  formData.append('mandatory', mandatory ? '1' : '0');
  formData.append(
    'custom_field_id',
    customfield_id ? customfield_id.toString() : ''
  );
  if (commentary) formData.append('commentary', commentary);
  try {
    const newDocument = await axiosClient.post(urlPostLinkedFiles, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (updateParentDocuments) {
      updateParentDocuments();
    }
    return newDocument.data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
};

export const checkLinkedFileSignature = async (
  file: File,
  relation_ids: number[],
  relation_type: string,
  file_type: number
) => {
  const formData = new FormData();
  formData.append('file', file);
  relation_ids.forEach((id) => {
    formData.append('relation_ids[]', id.toString());
  });
  formData.append('relation_type', relation_type);
  formData.append('file_type', file_type.toString());
  try {
    const response = await axiosClient.post(
      urlGetCheckLinkedFileSignature,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
};

// ENVOI DE DOCUMENTS APRES UNE INSCRIPTION SPONTANEE
export const sendRegistrationLinkedFiles = async (
  file: File,
  relation_ids: number[],
  relation_type: string,
  file_type: number,
  status: number,
  commentary: string | null,
  linked_entity_id: number | null,
  mandatory: boolean,
  documentId?: number,
  created_by?: number,
  updateParentDocuments?: () => Promise<void> | undefined,
  customfield_id?: number
) => {
  const formData = new FormData();
  if (documentId) {
    formData.append('id', documentId.toString());
  }
  if (created_by) {
    formData.append('created_by', created_by.toString());
  }
  if (customfield_id) {
    formData.append('custom_field_id', customfield_id.toString());
  }
  formData.append('file', file);
  relation_ids.forEach((id) => {
    formData.append('relation_ids[]', id.toString());
  });
  formData.append('relation_type', relation_type);
  formData.append('file_type', file_type.toString());
  formData.append('status', status.toString());
  formData.append('linked_entity_id', String(linked_entity_id || ''));
  formData.append('mandatory', mandatory ? '1' : '0');
  if (commentary) formData.append('commentary', commentary);
  try {
    const newDocument = await axiosClient.post(
      urlPostRegistrationLinkedFiles,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    if (updateParentDocuments) {
      updateParentDocuments();
    }
    return newDocument.data;
  } catch (error) {
    toast.error(
      `${error.response.status} ${
        error.response.statusText
      } ${error.response.data.errors.join(', ')}`
    );
  }
};

export const sendLinkedFileToWorksite = async (
  workisteId: number,
  file: File,
  relation_ids: number[],
  relation_type: string,
  file_type: number,
  status: number,
  commentary: string,
  linked_entity_id: number,
  mandatory: boolean,
  documentId?: number,
  updateWorksiteData?: Dispatch<SetStateAction<IWorksite>>,
  updateParentDocuments?: () => Promise<void>,
  existingDocumentId?: number
) => {
  // axiosClient.defaults.headers.common['Content-Type'] = 'multipart/form-data';
  const formData = new FormData();
  if (documentId) {
    formData.append('id', documentId.toString());
  }
  formData.append('file', file);
  relation_ids.forEach((id) => {
    formData.append('relation_ids[]', id.toString());
  });
  formData.append('relation_type', relation_type);
  formData.append('file_type', file_type.toString());
  formData.append('status', status.toString());
  formData.append('commentary', commentary);
  formData.append('linked_entity_id', linked_entity_id.toString());
  formData.append('mandatory', mandatory ? '1' : '0');
  if (existingDocumentId) {
    formData.append('id', existingDocumentId.toString());
  }

  try {
    const newDocument = await axiosClient.post(
      urlPostWorksiteLinkedFiles(workisteId),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    if (updateWorksiteData) {
      updateWorksiteData(newDocument.data.data);
    }
    if (updateParentDocuments) {
      updateParentDocuments();
    }
    return newDocument.data;
  } catch (error) {
    toast.error(error.response.data);
  }
};

// DELETE DOCUMENT
export const deleteLinkedFile = async (
  idFile: number,
  updateDocumentActive: Dispatch<SetStateAction<IActiveDocument>>
) => {
  try {
    await axiosClient.delete(urlLinkedFileAction(idFile));
    updateDocumentActive(initDocumentActive);
    return true;
  } catch (error) {
    toast.error(error.response.data.errors);
    return false;
  }
};

// DELETE DOCUMENTS SEND FROM REGISTRATION (COMPTE GHOST)
export const deleteRegistrationLinkedFile = async (
  file_id: number,
  document: ILinkedFile,
  deleteOneDocument: (doc: ILinkedFile) => void
) => {
  try {
    await axiosClient.delete(urlDeleteRegistrationLinkedFiles, {
      data: { file_id }, // Ajout de file_id dans le body
    });
    deleteOneDocument(document);
    return true;
  } catch (error) {
    toast.error(error.response.data.errors);
    return false;
  }
};

// UPDATE STATUS DOCUMENT CONTRAT => SIGNE ou ATTENTE SIGNATURE
export const updateContractLinkedFile = async (id: number, status: number) => {
  try {
    const response = await axiosClient.post(urlLinkedFileAction(id), {
      status,
    });
    return response.data;
  } catch (error) {
    toast.error(error.response.data.errors[0]);
    return false;
  }
};

// ACCEPTER OU REFUSER DOCUMENT
export const validateOrRejectDocument = async (
  idFile: number,
  statusFile: number,
  updateParentDocuments: (() => Promise<void>) | undefined,
  setIsLoading?: Dispatch<SetStateAction<boolean>>,
  rejectionComment?: string
) => {
  const formData = new FormData();
  formData.append('status', statusFile.toString());
  if (statusFile === 3 && rejectionComment) {
    formData.append('commentary', rejectionComment);
  }

  try {
    await axiosClient.post(urlLinkedFileAction(idFile), formData);
    if (updateParentDocuments) {
      updateParentDocuments();
    }
    if (setIsLoading) setIsLoading(false);
    return true;
  } catch (error) {
    toast.error(error.response.data.errors);
    return false;
  }
};

export const updateLinkedFile = async (idFile: number, file: File) => {
  try {
    const formData = new FormData();

    formData.append('file', file);

    await axiosClient.post(urlLinkedFileAction(idFile), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return true;
  } catch (error) {
    toast.error(error.response.data.errors);
    return false;
  }
};

export const fetchBlobs = async (linkedFiles: ILinkedFile[]) => {
  const requests = linkedFiles.map(async (file) => {
    const response = await axios.get(file.file_url || '', {
      responseType: 'blob',
    });
    return response.data;
  });

  try {
    return await Promise.all(requests);
  } catch (error) {
    toast.error(error);
  }
};

export const downloadFile = (url: string): Promise<AxiosResponse<Blob>> => {
  return axios({
    url,
    method: 'GET',
    responseType: 'blob',
  });
};

// GET LINKED FILE INFOS

export const getLinkedFile = async (id: number) => {
  try {
    const response = await axiosClient.get(urlLinkedFileAction(id));
    return response.data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return false;
  }
};

export const getLinkedFileArchives = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetLinkedFileArchives(id));
    return response.data;
  } catch (error) {
    if (error.response && error.response.data.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const getLinkedFileDocumentAnalysis = async (id: number) => {
  try {
    const response = await axiosClient.get(
      urlGetLinkedFileDocumentAnalysis(id)
    );
    return response.data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return false;
  }
};

export const getLinkedFileDocumentAnalysisCSV = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetExportCsvDocumentAnalysis(id));
    return response.data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return false;
  }
};

export const getFilters = async (
  filter: number,
  setFilters: Dispatch<SetStateAction<SortAndFilterType[]>>
) => {
  try {
    const response = await axiosClient.get(urlGetFilters(filter));
    setFilters(response.data);
  } catch (error) {
    toast.error(error.message);
  }
};

export const globalSearch = async (
  keyword: string,
  tags: number[],
  setSuggestions: Dispatch<SetStateAction<{ [x: number]: [] } | undefined>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  try {
    const response = await axiosClient.post(urlPostGlobalSearch, {
      keyword,
      tags,
    });
    setSuggestions(response.data);
    setIsLoading(false);
    return true;
  } catch (error) {
    toast.error(error.response.data.errors);
    setIsLoading(false);
  }
};
