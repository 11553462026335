import { toast } from 'react-toastify';
import { axiosClient } from '@api/apiClient';
import {
  urlBrandCreation,
  urlProductCreateByName,
  urlSettingsBrands,
  urlMaterialBrands,
  urlProductCreation,
  urlProductUpdate,
  urlProductDelete,
  urlBrandDelete,
} from '@api/apiUrls';
import { IMaterial } from '@models/materials/utils/materialTypes';
import { TFunction } from 'i18next';

export const getAllUserBrand = async () => {
  try {
    const response = await axiosClient.post(urlSettingsBrands);
    return response.data;
  } catch (error) {
    toast.error(error.message);
    return false;
  }
};

export const getAllMaterialsByBrandId = async (id: number, t: TFunction) => {
  try {
    const url = `${urlMaterialBrands}/${id}`;
    const response = await axiosClient.post(url, {});
    return response.data;
  } catch (error) {
    toast.error(t('brand.get_error'));
    return false;
  }
};

export const storeBrand = async (name: string, t: TFunction) => {
  try {
    const response = await axiosClient.post(urlBrandCreation, { name });
    toast.success(t('brand.added'));
    return response.data;
  } catch (error) {
    toast.error(t('brand.add_error'));
    return false;
  }
};

export const storeProduct = async (
  brand_name: string,
  name: string,
  t: TFunction
) => {
  try {
    const response = await axiosClient.post(urlProductCreateByName, {
      brand_name,
      name,
    });
    toast.success(t('brand.products.success_message'));
    return response.data;
  } catch (error) {
    toast.error(t('brand.products.error_message'));
    return false;
  }
};

type IMaterialStore = {
  name: string;
  brand_id: number;
};
export const storeMaterial = async (
  materialData: IMaterialStore,
  t: TFunction
) => {
  try {
    const response = await axiosClient.post(urlProductCreation, materialData);
    toast.success(t('brand.products.success_message'));
    return response.data;
  } catch (error) {
    toast.error(t('brand.products.error_message'));
    return false;
  }
};

export const deleteBrand = async (id: number, t: TFunction) => {
  try {
    const response = await axiosClient.post(urlBrandDelete(id));
    toast.success(t('brand.deleted'));
    return response.data;
  } catch (error) {
    toast.error(t('brand.delete_error'));
    return false;
  }
};

export const updateMaterial = async (material: IMaterial, t: TFunction) => {
  try {
    const response = await axiosClient.post(
      urlProductUpdate(material.id),
      material
    );
    toast.success(t('brand.products.updated'));
    return response.data;
  } catch (error) {
    toast.error(t('brand.products.update_error'));
    return false;
  }
};

export const deleteMaterial = async (material: IMaterial, t: TFunction) => {
  try {
    const response = await axiosClient.post(
      urlProductDelete(material.id),
      material
    );
    toast.success(t('brand.products.deleted'));
    return response.data;
  } catch (error) {
    toast.error(t('brand.products.delete_error'));
    return false;
  }
};
