/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-spread */
import { Document, Page, pdfjs, PDFPageProxy } from 'react-pdf';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Pagination } from '@components/atomic/pagination/Pagination';
import { blueOpx } from '@assets/color';
import { LoaderCarouselDocument } from '@components/loaders/document/LoaderCarouselDocument';
import { LoaderDocument } from '@components/loaders/document/LoaderDocument';
import { LoaderPagination } from '@components/loaders/LoaderPagination';
import { LoaderHeaderDocument } from '@components/loaders/document/LoaderHeaderDocument';
import { GlobalContext } from '@context/globalContext';
import { v4 } from 'uuid';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { dateToDDMMYYY, formatWord } from '@utils/format';
import {
  getLinkedFileArchives,
  updateContractLinkedFile,
} from '@apiRequests/globalRequests';
import {
  customStatusSignature,
  DOCUMENT_STATE_STRING,
  DOCUMENT_TYPES,
  SIGNATURE_STATE,
} from '@utils/utils';
import { ShoppingCartIcon } from '@assets/images/svgComponents';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { getNbCredits } from '@models/entities/apiRequests/entitiesRequests';
import { ColorCube } from '@components/atomic/ColorCube';
import { t } from 'i18next';
import { Loader } from '@components/atomic/Loader';
import { getSignatureProcedure } from '@models/linkedFile/apiRequests/linkedFileRequests';
import { convertHexToRGBA } from '@utils/functions';
import { toast } from 'react-toastify';
import { ILinkedFile } from '../../types/globalTypes';
import DocumentStatusTag from './DocumentStatusTag';
import { FormInputSignature } from './DocumentViewerSignatureForms/FormInputSignature';
import { FormDisplaySignature } from './DocumentViewerSignatureForms/FormDisplaySignature';
import { DocumentModalCreditsPayment } from './DocumentModalCreditsPayment';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IDocumentViewerProps {
  isLoading: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  addClassHeight?: string;
  isSizingNote?: string;
  reference?: string;
  canEdit?: boolean;
  withoutHeader?: boolean;
  showArchives?: boolean;
}

interface ISignatureProcedure {
  beneficiary_email: string;
  beneficiary_phone_number: string;
  created_at: string;
  id: number;
  linked_file_id: number;
  signatory: string;
  signed_at: string;
  status: number;
}

function DocumentViewer({
  isLoading,
  setIsLoading,
  addClassHeight,
  isSizingNote,
  reference,
  canEdit,
  withoutHeader,
  showArchives,
}: IDocumentViewerProps) {
  // ---------- USE CONTEXT ----------
  const { globalEnum, documentActive, updateDocumentActive, userView } =
    useContext(GlobalContext);
  const { sizingNote } = useContext(WorksiteCreationContext);
  const { worksiteDetails } = useContext(WorksitesContext);

  // ---------- USE STATE -----------
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isRotate, setIsRotate] = useState<boolean>(false);
  const [pdfWidth, setPdfWidth] = useState<number>(0);
  const [widthToDisplay, setWidthToDisplay] = useState<number>(0);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<string>(
    documentActive
      ? globalEnum.linked_file_status[documentActive.document.status]
      : ''
  );
  const [urlDocumentActive, setUrlDocumentActive] = useState<string | null>(
    null
  );
  const [dateDocumentActive, setDateDocumentActive] = useState<string | null>(
    null
  );
  const [archiveData, setArchiveData] = useState<ILinkedFile[]>([]);
  const [isShowArchives, setIsShowArchives] = useState<boolean>(false);

  const [isModalBuyCredits, setIsModalBuyCredits] = useState(false);
  const [isAskingSignature, setIsAskingSignature] = useState(false);
  const [nbCreditsEntity, setNbCreditsEntity] = useState(0);
  const [signatureProcedure, setSignatureProcedure] =
    useState<ISignatureProcedure>();
  const [isFormulaireEnvoiSignature, setIsFormulaireEnvoiSignature] =
    useState(false);
  const [
    isFormulaireAnnulerRelancerSignature,
    setIsFormulaireAnnulerRelancerSignature,
  ] = useState(false);
  const [isFormulaireSigne, setIsFormulaireSigne] = useState(false);
  const [statusDoc, setStatusDoc] = useState<string>();

  // ----------- USE REF ----------
  const container = useRef<HTMLDivElement>(null);

  // ---------- FUNCTION ----------
  const resizePdf = () => {
    if (container && container.current) {
      const divWidth = container.current.clientWidth;
      setContainerWidth(divWidth);
    }
  };

  const onDocumentLoadSuccess = (pageNum: number) => {
    setNumPages(pageNum);
    setPageNumber(1);
    if (setIsLoading) {
      setIsLoading(false);
    }
    if (isRotate) {
      setIsRotate(false);
    }
  };

  const handleLoadSuccess = (page: PDFPageProxy) => {
    if (page.width > page.height || page.width >= 842) {
      setIsRotate(true);
      setPdfWidth(page.width);
    }
  };

  const getSize = () => {
    if (urlDocumentActive) {
      const img = new Image();
      img.src = urlDocumentActive;
      img.onload = () => {
        if (img.width > img.height) {
          setIsRotate(true);
        } else {
          setIsRotate(false);
        }
      };
    }
  };

  /**
   * Cette fonction gère le comportement spécifique pour les documents de type "Attestation sur l'Honneur" (AH).
   */
  const manageAHBehavior = async () => {
    if (documentActive.document.file_type === DOCUMENT_TYPES.AH) {
      documentActive.document.custom_title = t(
        'global.document_ah_generated'
      ).toString();

      if (
        worksiteDetails &&
        worksiteDetails.convention &&
        worksiteDetails.convention.is_electronic_signature
      ) {
        if (documentActive.document.id) {
          const resultGetSignatureProcedure = await getSignatureProcedure(
            documentActive.document.id
          );
          if (resultGetSignatureProcedure.status === 404) {
            setSignatureProcedure(undefined);
          }
          if (resultGetSignatureProcedure.data.data) {
            setSignatureProcedure(resultGetSignatureProcedure.data.data);
          }
        }
      }

      if (userView && userView.entity_id) {
        getNbCredits(userView.entity_id).then((res) => {
          if (res) {
            setNbCreditsEntity(res);
          }
        });
      }
    }
  };

  const getWidth = () => {
    const width = containerWidth;
    setWidthToDisplay(width);
  };

  const getArchives = async (id: number) => {
    const response = await getLinkedFileArchives(id);
    if (response && response.data) {
      setArchiveData(response.data);
    }
  };

  const changeStatus = (label: string) => {
    setSelectedStatus(label);
    const element = Object.values(globalEnum.linked_file_status).indexOf(label);
    const actualStatus =
      globalEnum.linked_file_status[documentActive.document.status];
    const status = Object.entries(globalEnum.linked_file_status)[element][0];
    if (
      status &&
      documentActive &&
      documentActive.document &&
      documentActive.document.id &&
      actualStatus !== label
    ) {
      updateContractLinkedFile(documentActive.document.id, Number(status)).then(
        (res) => {
          if (res.data) updateDocumentActive({ list: '', document: res.data });
        }
      );
    }
  };

  /**
   * setIsFormulaireEnvoiSignature(true) si :
   * 1. Le document est de type AH
   * 2. La convention du chantier autorise la signature électronique
   * 4. Il n'y a pas de SignatureProcedure liée au document, ou s'il existe, sont statut est égale à CANCELED
   */
  const manageIsFormulaireEnvoiSignature = () => {
    setIsFormulaireEnvoiSignature(true);
    if (documentActive.document.file_type !== DOCUMENT_TYPES.AH)
      setIsFormulaireEnvoiSignature(false);
    if (worksiteDetails && !worksiteDetails.convention.is_electronic_signature)
      setIsFormulaireEnvoiSignature(false);
    if (
      signatureProcedure &&
      (customStatusSignature[signatureProcedure.status] ===
        SIGNATURE_STATE.AWAITING_SIGNATURE ||
        customStatusSignature[signatureProcedure.status] ===
          SIGNATURE_STATE.SIGNED)
    ) {
      setIsFormulaireEnvoiSignature(false);
    }
  };

  /**
   * setIsFormulaireAnnulerRelancerSignature(true) si :
   * 1. Le document est de type AH
   * 3. La convention du chantier autorise la signature électronique
   * 4. Il y a une SignatureProcedure liée au document et son statut est égale à AWAITING_SIGNATURE
   */
  const manageIsFormulaireAnnulerRelancerSignature = () => {
    setIsFormulaireAnnulerRelancerSignature(true);
    if (documentActive.document.file_type !== DOCUMENT_TYPES.AH)
      setIsFormulaireAnnulerRelancerSignature(false);
    if (worksiteDetails && !worksiteDetails.convention.is_electronic_signature)
      setIsFormulaireAnnulerRelancerSignature(false);
    if (
      !signatureProcedure ||
      (customStatusSignature[signatureProcedure.status] !==
        SIGNATURE_STATE.AWAITING_SIGNATURE &&
        customStatusSignature[signatureProcedure.status] !==
          SIGNATURE_STATE.SIGNED)
    )
      setIsFormulaireAnnulerRelancerSignature(false);
  };

  /**
   * setIsFormulaireSigne(true) si :
   * 1. Le document est de type AH
   * 3. La convention du chantier autorise la signature électronique
   * 4. Il y a une SignatureProcedure liée au document et son statut est égale à SIGNED
   */
  const manageIsFormulaireSigne = () => {
    setIsFormulaireSigne(true);
    if (documentActive.document.file_type !== DOCUMENT_TYPES.AH)
      setIsFormulaireSigne(false);
    if (worksiteDetails && !worksiteDetails.convention.is_electronic_signature)
      setIsFormulaireSigne(false);
    if (
      !signatureProcedure ||
      customStatusSignature[signatureProcedure.status] !==
        SIGNATURE_STATE.SIGNED
    )
      setIsFormulaireSigne(false);
  };

  // ---------- USE MEMO ----------
  const docStatus = useMemo(() => {
    const label = globalEnum.linked_file_status[documentActive.document.status];
    setSelectedStatus(label);
    return label;
  }, [documentActive]);

  const typeIsImage = useMemo(() => {
    const isBlob = urlDocumentActive?.includes('blob:');
    const parts = urlDocumentActive?.split('?')[0].split('.');
    if (!parts) return false;
    const extension = parts[parts.length - 1];

    return (
      !isBlob &&
      ((extension && extension.toLowerCase() !== 'pdf') ||
        [6, 12].includes(documentActive.document.file_type))
    );
  }, [documentActive]);

  const inputColor = useMemo(() => {
    if (formatWord(selectedStatus).includes('signe')) return 'success';
    return undefined;
  }, [selectedStatus]);

  const archives = useMemo(() => {
    if (!archiveData) return [];

    const dataArchive = archiveData
      .filter((archive) => archive.file_url !== null)
      .map((archive) => ({
        value: String(archive.file_url || ''),
        label: dateToDDMMYYY(archive.uploaded_at || '', true),
      }));

    return [
      {
        value: String(documentActive.document.file_url || ''),
        label:
          documentActive.document.uploaded_at &&
          !documentActive.document.uploaded_at.includes('/')
            ? dateToDDMMYYY(documentActive.document.uploaded_at || '', true)
            : documentActive.document.uploaded_at || '',
      },
      ...dataArchive,
    ];
  }, [documentActive, archiveData]);

  // ---------- USE EFFECT ----------
  useEffect(() => {
    window.addEventListener('resize', resizePdf);
    resizePdf();
  }, []);

  useEffect(() => {
    resizePdf();
  }, [urlDocumentActive]);

  useEffect(() => {
    setTimeout(() => {
      resizePdf();
    }, 1000);
  }, [sizingNote]);

  useEffect(() => {
    getWidth();
  }, [pdfWidth, containerWidth, sizingNote]);

  useEffect(() => {
    if (signatureProcedure) {
      // On met à jour l'affichage du statut du document en fonction du statut de la procédure de signature
      switch (customStatusSignature[signatureProcedure.status]) {
        case SIGNATURE_STATE.AWAITING_SIGNATURE:
          setStatusDoc(DOCUMENT_STATE_STRING.AWAITING_SIGNATURE);
          break;
        case SIGNATURE_STATE.SIGNED:
          setStatusDoc(DOCUMENT_STATE_STRING.SIGNED);
          break;
        default:
          setStatusDoc(DOCUMENT_STATE_STRING.TO_SIGN);
          break;
      }
    }
  }, [signatureProcedure]);

  useEffect(() => {
    setUrlDocumentActive(documentActive.document.file_url);

    let dateDoc = documentActive.document.uploaded_at || '';

    if (dateDoc !== '' && !dateDoc.includes('/')) {
      dateDoc = dateToDDMMYYY(dateDoc);
    }
    setDateDocumentActive(dateDoc);

    // on récupère les archives de tous les documents sauf les ah et les ah signées
    if (
      documentActive.document.id &&
      showArchives &&
      documentActive.document.file_type !== DOCUMENT_TYPES.AH
    ) {
      getArchives(documentActive.document.id);
    }

    manageAHBehavior();

    if (
      documentActive.document.file_type === DOCUMENT_TYPES.SignedAH &&
      documentActive.document.id
    ) {
      getSignatureProcedure(documentActive.document.id)
        .then((res) => {
          setSignatureProcedure(res?.data.data);
        })
        .catch((err) => {
          toast.error(err);
          console.error(err);
          setSignatureProcedure(undefined);
        });
      setIsFormulaireAnnulerRelancerSignature(true);
      setIsFormulaireSigne(true);
    }
  }, [documentActive]);

  useEffect(() => {
    manageIsFormulaireEnvoiSignature();
    manageIsFormulaireAnnulerRelancerSignature();
    manageIsFormulaireSigne();
  }, [signatureProcedure, documentActive]);

  // ---------- SOUS-COMPOSANT ----------
  const documentView = (
    <>
      <div className="w-full" ref={container} data-test-id="document">
        {isLoading ? (
          <LoaderDocument addClassHeight={addClassHeight} />
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href={isSizingNote !== undefined ? '' : String(urlDocumentActive)}
            target="_blank"
            rel="noreferrer"
          >
            <Document
              file={
                isSizingNote !== undefined ? isSizingNote : urlDocumentActive
              }
              options={{
                disableFontFace: true,
                disableRange: true,
                disableStream: true,
                disableAutoFetch: true,
                disableCreateObjectURL: true,
                cMapUrl: 'cmaps/',
                cMapPacked: true,
              }}
              onLoadSuccess={(pdf) => {
                onDocumentLoadSuccess(pdf.numPages);
              }}
              onLoadError={() => {
                setNumPages(0);
                setPageNumber(1);
              }}
              error={
                <div className="w-full h-full justify-center flex items-center">
                  <div className="text-[.75rem] font-light">
                    {t('global.document_no_display')}
                  </div>
                </div>
              }
              loading={<LoaderDocument addClassHeight={addClassHeight} />}
              className="w-full"
            >
              {isRotate ? (
                <Page
                  pageNumber={pageNumber}
                  onRenderSuccess={(page) => handleLoadSuccess(page)}
                  rotate={pdfWidth < 842 ? 90 : 180}
                  className={`overflow-auto scroll-invisible h-[65vh] ${
                    addClassHeight || 'max-h-[65vh]'
                  } ${
                    !isSizingNote
                      ? 'rounded-default border-borderGrey border-[1px]'
                      : ''
                  } `}
                  width={widthToDisplay}
                  renderAnnotationLayer={false}
                />
              ) : (
                <Page
                  pageNumber={pageNumber}
                  onRenderSuccess={(page) => handleLoadSuccess(page)}
                  width={widthToDisplay}
                  className={`overflow-auto scroll-invisible h-[65vh] ${
                    addClassHeight || 'max-h-[65vh]'
                  } ${
                    !isSizingNote
                      ? 'rounded-default border-borderGrey border-[1px]'
                      : ''
                  }`}
                  renderAnnotationLayer={false}
                />
              )}
            </Document>
          </a>
        )}
      </div>
      <div className="w-full flex items-center max-w-full ">
        {isLoading ? (
          <LoaderCarouselDocument />
        ) : (
          <Document
            file={isSizingNote !== undefined ? isSizingNote : urlDocumentActive}
            options={{
              disableFontFace: true,
              disableRange: true,
              disableStream: true,
              disableAutoFetch: true,
              disableCreateObjectURL: true,
              cMapUrl: 'cmaps/',
              cMapPacked: true,
            }}
            onLoadSuccess={(pdf) => {
              onDocumentLoadSuccess(pdf.numPages);
            }}
            className="mx-auto flex items-center space-x-[1rem]"
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => (
                <button
                  key={v4()}
                  type="button"
                  onClick={() => setPageNumber(page)}
                  className={`rounded-default border-[1px] h-[3.5rem] max-h-[3.5rem] w-[3.5rem] max-w-[3.5rem] overflow-hidden ${
                    page === pageNumber ? 'border-blueOpx' : 'border-borderGrey'
                  }`}
                >
                  <Page
                    pageNumber={page}
                    width={100}
                    height={100}
                    renderAnnotationLayer={false}
                  />
                </button>
              ))}
          </Document>
        )}
      </div>
      {isLoading ? (
        <LoaderPagination />
      ) : (
        <div>
          {numPages > 1 && (
            <Pagination
              colorPagination={blueOpx}
              numberPage={pageNumber}
              totalNumberPage={0}
              onPrevious={() => {
                if (pageNumber > 1) {
                  setPageNumber((prevPageNumber) => prevPageNumber - 1);
                }
              }}
              onNext={() => {
                if (pageNumber < numPages) {
                  setPageNumber((prevPageNumber) => prevPageNumber + 1);
                }
              }}
            />
          )}
        </div>
      )}
    </>
  );

  // ---------- RETURN ----------
  return (
    <div
      className={`w-full bg-white border border-borderGrey rounded-default overflow-hidden ${
        !isSizingNote ? 'py-[1.5rem] px-[2.75rem]' : ''
      }  flex flex-col space-y-[1.5rem]`}
      data-test-id="document_viewer"
    >
      {!urlDocumentActive && !isLoading && !isSizingNote ? (
        <>
          <div className="flex flex-col space-y-[.25rem]">
            <p className="text-[1rem] leading-[1.25rem]">
              {documentActive.list}
            </p>
          </div>
          <div
            className={`w-full ${
              addClassHeight ? 'h-[60vh]' : 'h-[80vh]'
            } rounded-default bg-backgroundBody`}
          />
        </>
      ) : (
        <>
          {isLoading && !isSizingNote ? (
            <LoaderHeaderDocument />
          ) : !isSizingNote && !isLoading && !withoutHeader ? (
            <>
              <div className="flex justify-between relative">
                <div className="flex flex-col gap-y-[1rem] space-y-[.25rem] w-full">
                  <div className="flex w-full justify-between">
                    <div>
                      <div className="flex gap-x-[.5rem]">
                        {!isAskingSignature && (
                          <>
                            <p
                              className="text-[1rem]"
                              data-test-id="document_title"
                            >
                              {documentActive.document.custom_title
                                ? documentActive.document.custom_title
                                : reference || documentActive.listAsTitle
                                ? documentActive.list
                                : globalEnum.linked_file_type[
                                    documentActive.document.file_type
                                  ]}
                            </p>
                            {/* sizingNote fait parti du worksiteCreation
                                ici on l'utilise pour cacher le statut lors de la création du chantier */}
                            {sizingNote === undefined &&
                              documentActive.document.file_type ===
                                DOCUMENT_TYPES.AH &&
                              worksiteDetails.convention
                                .is_electronic_signature &&
                              statusDoc !== '' && (
                                <DocumentStatusTag status={statusDoc} />
                              )}
                          </>
                        )}
                        {isAskingSignature &&
                          documentActive.document.file_type ===
                            DOCUMENT_TYPES.AH && (
                            <div className="flex gap-x-[.5rem]">
                              <p className="text-[1rem]">
                                {t('global.sending')}
                              </p>
                              <Loader />
                            </div>
                          )}
                      </div>
                      <p
                        className="text-[.75rem] text-textGrey"
                        data-test-id="document_date"
                      >
                        {urlDocumentActive !==
                          documentActive.document.file_url && !isFormulaireSigne
                          ? `archive (${dateDocumentActive})`
                          : documentActive.document.file_type !==
                              DOCUMENT_TYPES.AH && dateDocumentActive}
                        {documentActive.document.file_type ===
                          DOCUMENT_TYPES.AH &&
                          !isFormulaireSigne &&
                          `Générée le ${dateDocumentActive}`}
                      </p>
                      {isFormulaireSigne &&
                        signatureProcedure &&
                        signatureProcedure.signed_at && (
                          <p className="text-[0.75rem] text-textGrey">
                            {t('global.signed_at')} :{' '}
                            {dateToDDMMYYY(signatureProcedure?.signed_at)}
                          </p>
                        )}
                    </div>
                    {documentActive.document.file_type === DOCUMENT_TYPES.AH &&
                      worksiteDetails &&
                      worksiteDetails.convention &&
                      worksiteDetails.convention.is_electronic_signature && (
                        <div className="flex justify-end gap-x-[.5rem]">
                          <span className="text-[.75rem] text-textGrey mt-2">
                            {t('global.document_nb_credits')} :{' '}
                            {nbCreditsEntity}
                          </span>
                          <ColorCube
                            numberOrIcon={<ShoppingCartIcon />}
                            size="2rem"
                            backgroundColor={convertHexToRGBA(blueOpx, 0.1)}
                            color={blueOpx}
                            addClass="cursor-pointer p-2"
                            onClick={() => setIsModalBuyCredits(true)}
                          />
                          {isModalBuyCredits && (
                            <DocumentModalCreditsPayment
                              setNbCreditsEntity={setNbCreditsEntity}
                              setIsModalBuyCredits={setIsModalBuyCredits}
                            />
                          )}
                        </div>
                      )}
                    {archiveData &&
                      archiveData.length > 1 &&
                      documentActive.document.file_type !==
                        DOCUMENT_TYPES.AH && (
                        <div className="w-[15rem]">
                          <InputSelect
                            placeholder=""
                            dataLabelValue={archives}
                            valueInput="Versions"
                            onSelectLabelValue={(item) => {
                              setUrlDocumentActive(item.value);
                              setDateDocumentActive(item.label);
                              setIsShowArchives(true);
                            }}
                            hideScrollBar
                            disabled={isAskingSignature}
                          />
                        </div>
                      )}
                  </div>
                </div>
                {canEdit && (
                  <InputSelect
                    placeholder=""
                    defaultSelected={docStatus}
                    dataArrayString={Object.values(
                      globalEnum.linked_file_status
                    ).filter(
                      (elt) =>
                        elt.includes(DOCUMENT_STATE_STRING.SIGNED) ||
                        elt.includes(DOCUMENT_STATE_STRING.AWAITING_SIGNATURE)
                    )}
                    addClass="w-[13.5rem] absolute right-0 top-[-1rem] z-20"
                    onSelect={(value) => changeStatus(String(value))}
                    color={inputColor}
                    borderRadius={6}
                  />
                )}
              </div>
              {isFormulaireEnvoiSignature && !isShowArchives && (
                <FormInputSignature
                  setIsFormulaireEnvoiSignature={setIsFormulaireEnvoiSignature}
                  setIsFormulaireAnnulerRelancerSignature={
                    setIsFormulaireAnnulerRelancerSignature
                  }
                  nbCreditsEntity={nbCreditsEntity}
                  setNbCreditsEntity={setNbCreditsEntity}
                  setSignatureProcedure={setSignatureProcedure}
                  isAskingSignature={isAskingSignature}
                  setIsAskingSignature={setIsAskingSignature}
                  setStatusDoc={setStatusDoc}
                />
              )}
              {isFormulaireAnnulerRelancerSignature && (
                <FormDisplaySignature
                  signatureProcedure={signatureProcedure}
                  setIsFormulaireAnnulerRelancerSignature={
                    setIsFormulaireAnnulerRelancerSignature
                  }
                  setIsFormulaireEnvoiSignature={setIsFormulaireEnvoiSignature}
                  nbCreditsEntity={nbCreditsEntity}
                  setNbCreditsEntity={setNbCreditsEntity}
                  isFormulaireSigne={isFormulaireSigne}
                  setStatusDoc={setStatusDoc}
                />
              )}
            </>
          ) : null}
          {typeIsImage ? (
            <div className="overflow-auto" style={{ width: '100%' }}>
              <img
                src={urlDocumentActive || ''}
                alt="document active"
                className="rounded-default max-w-none"
                onLoad={getSize}
              />
            </div>
          ) : (
            documentView
          )}
        </>
      )}
    </div>
  );
}

export { DocumentViewer };

DocumentViewer.defaultProps = {
  addClassHeight: undefined,
  isSizingNote: undefined,
  reference: '',
  canEdit: false,
  withoutHeader: false,
  showArchives: false,
  setIsLoading: undefined,
};
