import { useTranslation } from 'react-i18next';
import { KpiTab } from '@components/atomic/KpiTab';
import {
  CheckIcon,
  ClockAlertOutline,
  CrossIcon,
  MultipleFilesIcon,
} from '@assets/images/svgComponents';
import { red, green, blueOpx } from '@assets/color';
import { ListDocumentsCard } from '@components/documents/ListDocumentsCard';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DocumentViewer } from '@components/documents/DocumentViewer';
import { LoaderKpiTab } from '@components/loaders/LoaderKpiTab';
import { v4 } from 'uuid';
import { LoaderListDocumentCard } from '@components/loaders/document/LoaderListDocumentCard';
import { GlobalContext } from '@context/globalContext';
import { DOCUMENT_STATE_STRING } from '@utils/utils';
import { getWorksiteDetails } from '@models/worksites/apiRequests/worksitesRequests';
import { HeaderContext } from '@context/headerContext';
import AddDocumentModal from '@components/documents/AddDocumentModal';
import {
  extractAllWorksiteLinkedFiles,
  extractCustomFieldFiles,
} from '@utils/functions';

type IWorksiteDetailsDocumentsProps = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

function WorksiteDetailsDocuments({
  isLoading,
  setIsLoading,
}: IWorksiteDetailsDocumentsProps): JSX.Element {
  const { t } = useTranslation();
  const { globalEnum, addDocumentModalIsActive } = useContext(GlobalContext);

  const { updateTitleHeader } = useContext(HeaderContext);
  const { worksiteDetails, updateWorksiteDetails, worksiteId } =
    useContext(WorksitesContext);

  const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(true);

  const { worksites_operations: worksitesOperations } = worksiteDetails;

  const numberOfFilesByTypes = (statut: string) => {
    if (statut === t('worksites.documents.total')) {
      const numberOfGlobalFilesWithoutUrl = worksiteDetails.linkedFiles.filter(
        (el) => el.file_url !== null
      ).length;
      const total =
        numberOfGlobalFilesWithoutUrl +
        worksitesOperations.reduce((acc, obj) => {
          let counter = acc;
          obj.linkedFiles.forEach((file) => {
            if (file.file_url !== null) {
              counter += 1;
            }
          });
          return counter;
        }, 0);

      return total.toString();
    }
    const global = worksiteDetails.linkedFiles.filter(
      (el) => globalEnum.linked_file_status[el.status] === statut
    );
    let operations = 0;
    for (let i = 0; i < worksitesOperations.length; i += 1) {
      operations += worksitesOperations[i].linkedFiles.filter(
        (el) => globalEnum.linked_file_status[el.status] === statut
      ).length;
    }
    const result = global.length + operations;
    return result.toString();
  };

  const updateParentDocuments = async () => {
    getWorksiteDetails(
      updateWorksiteDetails,
      worksiteId,
      setIsLoading,
      updateTitleHeader
    );
  };

  const kpiTabWorksiteDetailsDocuments = useMemo(
    () => [
      {
        infos: [
          {
            title: t('worksites.documents.to_process'),
            subtitle: numberOfFilesByTypes(DOCUMENT_STATE_STRING.PENDING),
          },
        ],
        icon: <ClockAlertOutline />,
        colorIcon: blueOpx,
        dataTestId: 'worksite_docs_pending',
      },
      {
        infos: [
          {
            title: t('worksites.documents.non_compliant'),
            subtitle: numberOfFilesByTypes(DOCUMENT_STATE_STRING.REFUSE),
          },
        ],
        icon: <CrossIcon />,
        colorIcon: red,
        dataTestId: 'worksite_docs_refused',
      },
      {
        infos: [
          {
            title: t('worksites.documents.validated'),
            subtitle: numberOfFilesByTypes(DOCUMENT_STATE_STRING.VALIDATE),
          },
        ],
        icon: <CheckIcon />,
        colorIcon: green,
        dataTestId: 'worksite_docs_validated',
      },
      {
        infos: [
          {
            title: t('worksites.documents.total'),
            subtitle: numberOfFilesByTypes(t('worksites.documents.total')),
          },
        ],
        icon: <MultipleFilesIcon />,
        colorIcon: blueOpx,
        dataTestId: 'worksite_docs_total',
      },
    ],
    [worksiteDetails]
  );

  const allLinkedFiles = useMemo(
    () => extractAllWorksiteLinkedFiles(worksiteDetails),
    [worksiteDetails]
  );

  const customFieldFiles = useMemo(
    () => extractCustomFieldFiles(worksiteDetails),
    [worksiteDetails]
  );

  useEffect(() => {
    const tab = [];
    for (let i = 0; i < worksitesOperations.length; i += 1) {
      tab.push({
        title: worksitesOperations[i].operation.description,
        documents: worksitesOperations[i].linkedFiles,
      });
    }
  }, [worksiteDetails]);

  useEffect(() => {
    // WARN : To comment this part solves OPX-2634 (check it before delete these lines)
    // imports addFiscalNameToTitle and updateDocumentActive has been deleted
    /*
    const globalDoc = worksiteDetails.linkedFiles.find((el) => el.status !== 1);
    if (globalDoc) {
      console.log('WorksiteDetailsDocuments globalDoc', globalDoc);
      updateDocumentActive({
        list: addFiscalNameToTitle(globalDoc, globalEnum, worksiteDetails),
        document: globalDoc,
        listAsTitle: true,
      });
    } else {
      for (let i = 0; i < worksitesOperations.length; i += 1) {
        const worksiteOp = worksitesOperations[i];
        const opFile = worksiteOp.linkedFiles.find((el) => el.status !== 1);
        if (opFile) {
          console.log('WorksiteDetailsDocuments opFile', opFile);
          updateDocumentActive({
            list: worksiteOp.operation.description,
            document: opFile,
          });
          break;
        }
      }
    }
    */
    setIsLoadingDocument(false);
  }, [worksiteDetails]);

  return (
    <div className="w-full" data-test-id="worksite_details_documents">
      {addDocumentModalIsActive && (
        <AddDocumentModal updateParentDocuments={updateParentDocuments} />
      )}

      <div>
        {isLoading ? (
          <div className="flex items-center space-x-[1.5rem]">
            {[...Array(4)].map(() => (
              <div key={v4()} className="w-full">
                <LoaderKpiTab />
              </div>
            ))}
          </div>
        ) : (
          <KpiTab infos={kpiTabWorksiteDetailsDocuments} />
        )}
      </div>

      <div className="flex space-x-[1.5rem] py-[1.5rem]">
        <div className="w-1/2 flex flex-col space-y-[.5rem]">
          {isLoading ? (
            <LoaderListDocumentCard />
          ) : (
            allLinkedFiles !== undefined && (
              <ListDocumentsCard
                documents={allLinkedFiles}
                updateParentDocuments={updateParentDocuments}
                setIsLoading={setIsLoadingDocument}
                // arrayFiscalNames={arrayNamesFiscal}
                worksiteDatas={worksiteDetails}
                customFieldFiles={customFieldFiles}
                showAnalysis
              />
            )
          )}
        </div>
        <div className="w-1/2">
          <DocumentViewer
            isLoading={isLoadingDocument}
            setIsLoading={setIsLoadingDocument}
            showArchives
          />
        </div>
      </div>
    </div>
  );
}

export { WorksiteDetailsDocuments };
