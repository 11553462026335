/* eslint-disable no-restricted-syntax */
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { IGraph } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import {
  StepsWorksiteCreationEnum,
  HABITATION,
  HABITATION_ZONE,
} from '@models/worksiteCreation/utils/enums';
import { CadastreLocation } from '@models/worksiteCreation/components/createWorksite/infoLogement/CadastreLocation';
import { UndividedHousing } from '@models/worksiteCreation/components/createWorksite/infoLogement/UndividedHousing';
import { checkGeneralFields } from '@models/worksiteCreation/utils/functions';
import { OperationTypeEnum } from '@utils/enums';
import { CardForm } from '../../CardForm';
import { RenderFormGraphGeneral } from './RenderFormGraphGeneral';
import GeneralInformationForm from './GeneralInformationForm';

function StepHabitationSimulation() {
  const [undividedHousing, setUndividedHousing] = useState<boolean>(false);
  const {
    graphGeneral,
    updateSimulatorData,
    simulatorData,
    updateStepActiveWorksiteCreation,
    worksiteOperationType,
    updateInformationBeneficiaryDataPost,
    worksiteAddress,
    updateDisabledNextButton,
    updateSimulatorDataOperation,
    listOperationSelected,
  } = useContext(WorksiteCreationContext);

  const { parcelPrefix, parcelSection, parcelNumber } = worksiteAddress;

  const methods = useForm();

  // Retourne "true" si toutes les opérations sélectionnées sont parmis les opérations passées en paramètre
  const allSelectedOperationsMatchCondition = (
    operationsCheked: string[]
  ): boolean =>
    listOperationSelected?.every((item) =>
      operationsCheked.includes(item.code.toUpperCase())
    ) ?? false;

  const onlyHouseForOperationSelected: boolean =
    allSelectedOperationsMatchCondition([
      'BAR-TH-101',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-172',
    ]);

  const onlyApartmentForOperationSelected: boolean =
    allSelectedOperationsMatchCondition([
      'BAR-TH-107',
      'BAR-TH-107-SE',
      'BAR-TH-123',
      'BAR-TH-139',
      'BAR-TH-160',
      'BAR-TH-161',
    ]);

  const onSubmitFormGraphGeneral = async (formData: FieldValues) => {
    let updatedSimulatorData = { ...simulatorData };
    for (const [groupKey, groupValue] of Object.entries(formData)) {
      for (const [fieldKey, fieldValue] of Object.entries(groupValue)) {
        updatedSimulatorData = {
          ...updatedSimulatorData,
          [`${groupKey}.${fieldKey}`]:
            typeof fieldValue === 'string' ? fieldValue : '',
        };
      }
    }

    if (
      formData.general &&
      Object.keys(formData.general).includes('habitationNumberContracted')
    ) {
      updateSimulatorDataOperation((prevState: any) => {
        const dataArray = Array.isArray(prevState)
          ? [...prevState]
          : [prevState];
        return dataArray.map((elt) => ({
          ...elt,
          'operation.habitationNumber': formData.general.habitationNumber,
        }));
      });
    }

    if (simulatorData['general.energy.system'] !== undefined) {
      // Chaudière & Poêle => combustible (sauf chaudière électrique), le reste => electrique
      const isCombustibleSystem =
        (simulatorData['general.energy.system'] === 'boiler' &&
          simulatorData['general.energy.mode'] !== 'electric') ||
        simulatorData['general.energy.system'] === 'stove';

      updatedSimulatorData = {
        ...updatedSimulatorData,
        [`general.energy`]: isCombustibleSystem ? 'combustible' : 'electricity',
      };
    }

    // Gestion des données liées au logement en zone qpv ou conventionnée
    const isHouse = simulatorData['general.habitation'] === HABITATION.HOUSE;
    const isApartment =
      simulatorData['general.habitation'] === HABITATION.APARTMENT;
    const isZoneQpv =
      simulatorData['general.habitationZone'] === HABITATION_ZONE.QPV;
    const isContracted =
      simulatorData['general.habitationZone'] === HABITATION_ZONE.CONTRACTED;

    const hasOnlyBarTh173Selected = allSelectedOperationsMatchCondition([
      'BAR-TH-173',
    ]);

    // On force la valeur à "house"
    if (onlyHouseForOperationSelected) {
      updatedSimulatorData = {
        ...updatedSimulatorData,
        'general.habitation': HABITATION.HOUSE,
      };
    }
    // On force la valeur à "apartment"
    if (onlyApartmentForOperationSelected) {
      updatedSimulatorData = {
        ...updatedSimulatorData,
        'general.habitation': HABITATION.APARTMENT,
      };
    }

    if (
      (isHouse || hasOnlyBarTh173Selected || onlyHouseForOperationSelected) &&
      isZoneQpv
    ) {
      updatedSimulatorData = {
        ...updatedSimulatorData,
        'general.habitation': HABITATION.HOUSE,
        'general.habitationNumber': 1,
        'general.habitationNumberQpv': 1,
        'general.habitationNumberContracted': null,
      };
    } else if (
      (isHouse || hasOnlyBarTh173Selected || onlyHouseForOperationSelected) &&
      isContracted
    ) {
      updatedSimulatorData = {
        ...updatedSimulatorData,
        'general.habitation': HABITATION.HOUSE,
        'general.habitationNumber': 1,
        'general.habitationNumberContracted': 1,
        'general.habitationNumberQpv': null,
      };
    } else if (isApartment && isZoneQpv) {
      updatedSimulatorData = {
        ...updatedSimulatorData,
        'general.habitationNumberQpv':
          simulatorData['general.habitationNumber'],
      };
    }

    updateSimulatorData(updatedSimulatorData);

    const stepToGo =
      worksiteOperationType === OperationTypeEnum.B2B
        ? StepsWorksiteCreationEnum.SIMULATION_OPERATIONDETAILS
        : StepsWorksiteCreationEnum.SIMULATION_INFO_BENEFICIARY;

    updateStepActiveWorksiteCreation(stepToGo);
  };

  // En B2B, les champs "nombre de logements qpv" ou "logements conventionnés"
  // s'affichent en fonction du type d'habitation choisi
  const handleHabitationZone = (graph: IGraph) => {
    const habitationZone = simulatorData['general.habitationZone'];

    switch (habitationZone) {
      case HABITATION_ZONE.QPV:
        return graph.key !== 'general.habitationNumberContracted';
      case HABITATION_ZONE.CONTRACTED:
        return (
          graph.key !== 'general.habitationNumberQpv' &&
          graph.key !== 'general.qpvName' &&
          graph.key !== 'general.qpvCode'
        );
      case HABITATION_ZONE.OTHER:
      case null:
        return (
          graph.key !== 'general.habitationNumberQpv' &&
          graph.key !== 'general.habitationNumberContracted' &&
          graph.key !== 'general.qpvName' &&
          graph.key !== 'general.qpvCode'
        );
      default:
        return graph;
    }
  };

  // On masque les champs liés aux nombre de logements
  const handleHabitationNumber = (graph: IGraph) => {
    const habitationType = simulatorData['general.habitation'];
    const hasOnlyBarTh173Selected = allSelectedOperationsMatchCondition([
      'BAR-TH-173',
    ]);

    if (habitationType === HABITATION.HOUSE || hasOnlyBarTh173Selected) {
      return (
        graph.key !== 'general.habitationNumber' &&
        graph.key !== 'general.habitationNumberQpv' &&
        graph.key !== 'general.habitationNumberContracted'
      );
    }
    if (habitationType === HABITATION.APARTMENT) {
      return graph.key !== 'general.habitationNumberQpv';
    }
    return graph;
  };

  // Masque le champ surface lorsque pour certaines opérations, on sélectionne habitation = 'apartment'
  const handleHabitationSurface = (graph: IGraph) => {
    let newGraph = { ...graph };
    const apartment =
      simulatorData['general.habitation'] === HABITATION.APARTMENT;
    const noNeedSurface: boolean = allSelectedOperationsMatchCondition([
      'BAR-TH-129',
      'BAR-TH-137',
      'BAR-TH-159',
      'BAR-TH-167',
      'BAR-TH-171',
      'BAR-TH-173',
      'BAR-EQ-115',
    ]);
    if (apartment && noNeedSurface) {
      if (
        newGraph.childrens &&
        newGraph.childrens.some((child) => child.key === 'general.habitation')
      ) {
        newGraph = {
          ...graph,
          childrens: newGraph.childrens.filter(
            (child) => child.key !== 'general.surface'
          ),
        };
      }
    }
    return newGraph;
  };

  const extractKeys = (graph: IGraph) => {
    // Ensemble pour stocker les clés uniques
    const uniqueKeys = new Set<string>();

    // Fonction récursive pour traiter chaque noeud enfant
    const extractKeysRecursive = (node: IGraph) => {
      if (node.childrens && node.optional !== true) {
        // Parcours les enfants et applique la fonction de façon récursive
        node.childrens.forEach((child: IGraph) => extractKeysRecursive(child));
      }

      if (node.key && !uniqueKeys.has(node.key) && node.optional !== true) {
        if (node.key.includes('worksiteDates')) {
          uniqueKeys.add('general.startDate');
          uniqueKeys.add('general.endDate');
        } else {
          uniqueKeys.add(node.key);
        }
      }
    };

    extractKeysRecursive(graph);

    return Array.from(uniqueKeys);
  };

  const graphFiltered = useMemo(() => {
    return graphGeneral
      .filter((graph) => graph.key !== 'general.income')
      .filter((graph) => handleHabitationZone(graph))
      .filter((graph) => handleHabitationNumber(graph))
      .map((graph) => {
        let newGraph = { ...graph };
        if (
          graph.childrens &&
          graph.childrens.some(
            (child) =>
              child.key === 'general.owner' || child.key === 'general.persons'
          )
        ) {
          newGraph = {
            ...newGraph,
            childrens: graph.childrens.filter(
              (child) =>
                child.key !== 'general.owner' && child.key !== 'general.persons'
            ),
          };
        }
        return handleHabitationSurface(newGraph);
      });
  }, [
    graphGeneral,
    simulatorData['general.habitationZone'],
    simulatorData['general.habitation'],
  ]);

  const fieldsNeeded = graphFiltered.flatMap((graph) => extractKeys(graph));

  useEffect(() => {
    if (worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION) {
      updateInformationBeneficiaryDataPost((prevState) => ({
        ...prevState,
        indivision: undividedHousing,
        cadastral_parcel: `${parcelPrefix} ${parcelSection} ${parcelNumber}`,
      }));
    }

    const addressOk =
      simulatorData['general.address'] &&
      simulatorData['general.address'].address &&
      simulatorData['general.address'].address !== '';

    let btnDisabled =
      !addressOk || !checkGeneralFields(fieldsNeeded, simulatorData);

    if (worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION) {
      if (parcelNumber === '' || parcelPrefix === '' || parcelSection === '') {
        btnDisabled = true;
      }
    }

    updateDisabledNextButton(btnDisabled);
  }, [
    parcelPrefix,
    parcelSection,
    parcelNumber,
    worksiteOperationType,
    simulatorData,
    undividedHousing,
    fieldsNeeded,
  ]);

  return (
    <div className="flex flex-wrap pb-[20rem]">
      {graphFiltered.map((graph, index) => {
        return (
          <div key={graph.name} className="w-full">
            <CardForm
              title={graph.name}
              subtitle={graph.description}
              idForm="sendGraphGeneral"
              onSubmit={onSubmitFormGraphGeneral}
              methods={methods}
            >
              <div className="mt-[1.5rem]">
                {graph.childrens ? (
                  <GeneralInformationForm infosGraph={graph.childrens} />
                ) : (
                  <RenderFormGraphGeneral graph={graph} />
                )}
              </div>
            </CardForm>
            {index === 1 &&
              worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION && (
                <>
                  <UndividedHousing
                    undividedHousing={undividedHousing}
                    setUndividedHousing={setUndividedHousing}
                    methods={methods}
                    worksiteOperationType={worksiteOperationType}
                  />
                  <CadastreLocation methods={methods} />
                </>
              )}
          </div>
        );
      })}
    </div>
  );
}

export { StepHabitationSimulation };
