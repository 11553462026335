/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { axiosClient } from '@api/apiClient';
import { Dispatch, SetStateAction } from 'react';
import { IOperation } from '@models/worksiteCreation/utils/types/operationTypes';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import {
  IGraph,
  IIncomes,
  IMaterials,
  ISimulatorData,
  ISimulatorDataOperation,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import { NavigateFunction } from 'react-router-dom';
import axios from 'axios';
import {
  IMaterial,
  IPayloadInfosBeneficiary,
  IPayloadInfosLogement,
  IPayloadMaterials,
  IPayloadSizingNote,
  IPayloadSubcontractor,
  IWorksite,
  IWorksiteAddress,
  IWorksiteOperation,
} from '@models/worksiteCreation/utils/types/worksitesType';
import { toast } from 'react-toastify';
import { IBeneficiary } from '@models/beneficiaries/utils/beneficiariesType';
import {
  urlConventions,
  urlGetBeneficiariesByOperationType,
  urlGetConventionOperations,
  urlGetIncomes,
  urlGetLatLngWithZipCode,
  urlGetOneBeneficiary,
  urlGetOrDeleteWorksite,
  urlGetParcelMap,
  urlGetRecentsBeneficiaries,
  urlGetWorksiteGraph,
  urlGetWorksiteWorkflow,
  urlMaterials,
  urlPostCalculatePrimes,
  urlMaterialProducts,
  urlPostSendCTAToBeRecontactedNotification,
  urlPostStoreBeneficiary,
  urlPostWorksiteInfosBeneficiary,
  urlPostWorksiteInfosLogement,
  urlPostWorksiteInfosMaterials,
  urlPostWorksiteInfosSubcontractors,
  urlPostWorksiteValidateAfterUploadFiles,
  urlPostWorksiteValidateCreation,
  urlWorksites,
  urlMaterialBrands,
} from '@api/apiUrls';
import { FieldValues } from 'react-hook-form';
import { TFunction } from 'i18next';
import { OperationTypeEnum } from '@utils/enums';
import { WORKSITES_ROUTES, WORKSITES_ROUTES_WITH_ID } from '@utils/routesUrls';
import { ILinkedFile } from '../../../types/globalTypes';
import { IConvention } from '../utils/types/conventionsTypes';
import { constructIncomesContentArray } from '../utils/functions';
import {
  ICategoryWorkflow,
  IWorkflowSidebar,
} from '../utils/types/sidebarTypes';

// RECUPERE LA PARCELLE CADASTRALE DUNE ADRESSE
export const getParcelMap = async (
  LatLng: { lat: number; lng: number },
  setCoordinate?: Dispatch<SetStateAction<{ lat: number; lng: number }[]>>,
  setIsLoading?: Dispatch<SetStateAction<boolean>>,
  setParcelError?: Dispatch<SetStateAction<boolean>>,
  setErrorMessage?: Dispatch<SetStateAction<string>>
) => {
  try {
    const url = urlGetParcelMap(LatLng.lng, LatLng.lat);
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const response = await axios({
      method: 'get',
      url,
      headers,
    });
    const results = response.data.features[0].geometry.coordinates[0][0];
    const coordinatesCadastre = results.map((item: string) => {
      return { lat: item[0], lng: item[1] };
    });
    if (setCoordinate) {
      setCoordinate(coordinatesCadastre);
    }
    if (setIsLoading) {
      setIsLoading(false);
    }
    if (setParcelError) {
      setParcelError(false);
    }
    return {
      parcelSection: response.data.features[0].properties.section,
      parcelNumber: response.data.features[0].properties.numero,
      parcelPrefix: response.data.features[0].properties.code_arr,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.errors);
    }
    if (setIsLoading) setIsLoading(false);
    if (setErrorMessage)
      setErrorMessage(
        'Nous rencontrons un problème. Merci de saisir manuellement vos informations cadastrales.'
      );
    return false;
  }
};

// RECUPERE LA LISTE DES CONVENTIONS => AVANT SIMULATION
export const getListConvention = async (
  setListConvention: Dispatch<SetStateAction<IConvention[]>>,
  setListConventionIsLoading: Dispatch<SetStateAction<boolean>>,
  operationType: number,
  numberPage: number,
  setEndOfListConvention: Dispatch<SetStateAction<boolean>>,
  scrollPagination?: boolean,
  partnerId?: number,
  perPage = 10
) => {
  const dataPost = {
    operationType,
    partnerId,
    pagination: {
      page: numberPage,
      perPage,
    },
  };
  setListConventionIsLoading(true);

  try {
    const response = await axiosClient.post(urlConventions, dataPost);
    const listConvention = response.data.data.data;

    if (response.data.meta.next_page_url === null) {
      setEndOfListConvention(true);
    }
    setEndOfListConvention(listConvention.length < 5);

    if (scrollPagination) {
      setListConvention((prevState) => [...prevState, ...listConvention]);
    } else {
      setListConvention(listConvention);
    }

    setListConventionIsLoading(false);
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
};

// RECUPERE LA LISTE DE TOUT LES BENEFICIAIFRES
export const getListAllBeneficiaries = async (
  setListBeneficiaries: Dispatch<SetStateAction<IBeneficiary[]>>,
  numberPageListBeneficiaries: number,
  operationType: number,
  keyword?: string,
  installerId?: number
) => {
  const dataPost = {
    operationType,
    installerId,
    pagination: {
      page: numberPageListBeneficiaries,
      perPage: 5,
    },
    keyword: keyword || '',
  };
  try {
    const response = await axiosClient.post(
      urlGetBeneficiariesByOperationType,
      dataPost
    );
    const listBeneficiaries = response.data.data.filter(
      (beneficiary: IBeneficiary) => {
        if (operationType === OperationTypeEnum.B2B) {
          return beneficiary.company_name !== null;
        }
        return beneficiary.firstname !== null && beneficiary.lastname !== null;
      }
    );
    setListBeneficiaries(listBeneficiaries);
    return true;
  } catch (error) {
    toast.error(error.response);
    return false;
  }
};

// RECUPERE UN BENEFICIAIRE SELON SON ID
export const getOneBeneficiary = async (
  idBeneficiary: number | null,
  updateBeneficiary: Dispatch<SetStateAction<IBeneficiary | null>>
) => {
  try {
    const response = await axiosClient.get(urlGetOneBeneficiary(idBeneficiary));
    const beneficiary = response.data.data;
    updateBeneficiary(beneficiary);
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
};

// RECUPERE LA LISTE DES DERNIERS BENEFICIAIRES
export const getListRecentBeneficiaries = async (
  setListRecentBeneficiaries: Dispatch<SetStateAction<IBeneficiary[]>>,
  operationType: number,
  installerId?: number
) => {
  try {
    const dataPost = {
      operationType,
      installerId,
    };

    const response = await axiosClient.post(
      urlGetRecentsBeneficiaries,
      dataPost
    );
    const listRecentBeneficiaries = response.data.data.filter(
      (beneficiary: IBeneficiary) => {
        if (operationType === OperationTypeEnum.B2B) {
          return beneficiary.company_name !== null;
        }
        return beneficiary.firstname !== null && beneficiary.lastname !== null;
      }
    );
    setListRecentBeneficiaries(listRecentBeneficiaries);
    return true;
  } catch (error) {
    toast.error(error.response.data.errors);
    return false;
  }
};

// CREATION D'UN NOUVEAU BENEFICIAIRE
export const createBeneficiary = async (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  updateBeneficiary: Dispatch<SetStateAction<IBeneficiary | null>>,
  dataPost: IBeneficiary,
  setIsError: Dispatch<SetStateAction<boolean>>,
  setErrorMessages: Dispatch<SetStateAction<string[] | undefined>>,
  conventionId?: number
) => {
  setIsLoading(true);

  const dataPostFilteres: any = dataPost;

  delete dataPostFilteres.worksites;

  try {
    const response = await axiosClient.put(
      urlPostStoreBeneficiary(conventionId),
      dataPost
    );
    updateBeneficiary(response.data.data);
    setIsLoading(false);
    return response.data.data;
  } catch (error) {
    if (error.response.data.errors) {
      setErrorMessages(error.response.data.errors);
    }
    setIsError(true);
    setIsLoading(false);
    return false;
  }
};

// RECUPERE LE WORKLOW DE LA CREATION DE CHANTIER (SIDEBAR)
export const getWorkflowSidebarSteps = async (
  worksiteId: number | null,
  setWorklow: Dispatch<SetStateAction<IWorkflowSidebar | undefined>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  operationType: number,
  worksiteOperations: IWorksiteOperation[]
) => {
  try {
    const response = await axiosClient.post(urlGetWorksiteWorkflow, {
      worksiteId,
      operationType,
    });
    const workflow = response.data.data;
    const worksitesOperationsWithMaterial = worksiteOperations.filter(
      (wo) =>
        wo.operation.can_have_material && wo.operation.code !== 'BAR-TH-129'
    );
    if (
      worksitesOperationsWithMaterial.length === 0 &&
      workflow.creation.find(
        (step: ICategoryWorkflow) =>
          step.value === StepsWorksiteCreationEnum.WORKSITE_CREATION_MATERIAL
      )
    ) {
      workflow.creation = workflow.creation.filter(
        (step: ICategoryWorkflow) =>
          step.value !== StepsWorksiteCreationEnum.WORKSITE_CREATION_MATERIAL
      );
    }
    setWorklow(response.data.data);
    setIsLoading(false);
    return response.data.data;
  } catch (error) {
    setIsLoading(false);
    toast.error(error.response.data.errors);
    return false;
  }
};

// RECUPERE LE GRAPH DE LA SIMULATION
export const getGraph = async (
  codesArray: string[],
  updateGraphGeneral: Dispatch<SetStateAction<IGraph[]>>,
  updateGraphOperation: Dispatch<SetStateAction<IGraph[]>>,
  setSimulatorData: Dispatch<SetStateAction<ISimulatorData>>,
  operationType: number,
  simulatorData: ISimulatorData,
  updateStepActiveWorksiteCreation?: Dispatch<
    SetStateAction<StepsWorksiteCreationEnum>
  >,
  setErrorGraphMessage?: Dispatch<SetStateAction<string>>,
  conventionId?: number,
  resetSimulatorData = true
) => {
  const dataPost = { codes: codesArray, conventionId, operationType };
  try {
    const response = await axiosClient.post(urlGetWorksiteGraph, dataPost);
    const graphData = response.data;

    const arrayKey: any = [];
    graphData.general.map((graph: any) => {
      return graph.childrens?.map((children: any) => {
        return arrayKey.push(children.key);
      });
    });
    graphData.general.map((graph: any) => {
      return arrayKey.push(graph.key);
    });

    const newSimulatorData: any = {};

    for (let i = 0; i < arrayKey.length; i += 1) {
      newSimulatorData[arrayKey[i]] = null;
    }
    newSimulatorData['general.address'] =
      simulatorData['general.address'] || null;
    newSimulatorData['general.zipcode'] =
      simulatorData['general.zipcode'] || null;

    const newKeys = Object.keys(newSimulatorData).filter(
      (key) => key !== 'undefined' && key !== 'general.works'
    );
    const newSimulatorDataFilter = newKeys.reduce((obj: any, key) => {
      obj[key] = newSimulatorData[key];
      return obj;
    }, {});

    if (resetSimulatorData)
      setSimulatorData((oldDatas) => {
        if (oldDatas.ts) {
          return {
            ...newSimulatorDataFilter,
            ts: oldDatas.ts,
          };
        }
        return newSimulatorDataFilter;
      });

    updateGraphGeneral(graphData.general);
    updateGraphOperation(graphData.operations);
    if (updateStepActiveWorksiteCreation) {
      updateStepActiveWorksiteCreation(
        StepsWorksiteCreationEnum.SIMULATION_LOGEMENT
      );
    }
  } catch (error) {
    if (setErrorGraphMessage) {
      setErrorGraphMessage(error.response.data.errors);
    } else if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
};

// RECUPERE LA LISTE DES OPERATIONS SELON LA CONVENTION CHOISIE
export const getListOperations = async (
  idConvention: number,
  setListOperations: Dispatch<SetStateAction<IOperation[]>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  numberPage: number,
  setTotalOperations: Dispatch<SetStateAction<number>>,
  operationType: number,
  keyWord: string,
  setOperationIsLoaded?: Dispatch<SetStateAction<boolean>>,
  listOperations?: IOperation[]
) => {
  if (numberPage === 1) {
    setIsLoading(true);
  }
  const dataPost = {
    pagination: {
      page: numberPage,
      perPage: operationType === OperationTypeEnum.B2B ? 300 : 10,
    },
    keyword: keyWord || '',
    operationType,
  };
  const loadedTimeOut = () => {
    if (setOperationIsLoaded) setOperationIsLoaded(false);
  };
  try {
    const response = await axiosClient.post(
      urlGetConventionOperations(idConvention),
      dataPost
    );
    setIsLoading(false);
    setTotalOperations(response.data.meta.total);
    setTimeout(loadedTimeOut, 1000);
    if (numberPage === 1) {
      setListOperations(response.data.data);
      return response.data.data;
    }
    if (response.data.data !== null) {
      const newData = response.data.data;
      const newDataInArray: IOperation[] = Object.values(newData);
      const listOperationsInArray: IOperation[] = Object.values(
        listOperations !== undefined ? listOperations : {}
      );
      const newArray = listOperationsInArray.concat(newDataInArray);
      setListOperations(newArray);
      return newArray;
    }
    return null;
  } catch (error) {
    setIsLoading(false);
    if (error.response && error.response.data && error.response.data.errors) {
      toast.error(error.response.data.errors.join(', '));
    }
    return null;
  }
};

export const createWorksite = async (
  conventionId: number,
  beneficiaryId: number,
  operationType: number,
  setWorksiteData: Dispatch<SetStateAction<IWorksite>>
) => {
  const data = {
    'convention.id': conventionId,
    'worksite.beneficiary_id': beneficiaryId,
    operationType,
  };

  try {
    const response = await axiosClient.post(urlWorksites, data);
    setWorksiteData(response.data.data);
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
};

//  SAUVEGARDER LA SIMULATION
export const saveSimulation = async (
  router: NavigateFunction,
  dataPost: ISimulatorData,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  updateIsSaveAndQuit: Dispatch<
    SetStateAction<{
      formBeforeSimulation: boolean;
      bindBeneficiary: boolean;
      createBeneficiary: boolean;
      saveAndQuit: boolean;
      deleteWorksite: boolean;
    }>
  >,
  operationType: number,
  incentiveType: number,
  idWorksite?: number
) => {
  setIsLoading(true);
  const dataPostWithIdWorksite = {
    ...dataPost,
    'worksite.id': idWorksite,
    operationType,
    incentiveType,
  };
  try {
    await axiosClient.post(urlWorksites, dataPostWithIdWorksite);
    updateIsSaveAndQuit({
      formBeforeSimulation: true,
      bindBeneficiary: false,
      createBeneficiary: false,
      saveAndQuit: false,
      deleteWorksite: false,
    });
    setIsLoading(false);
    router(WORKSITES_ROUTES.WORKSITES);
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
};

//  RECUPERE LE TABLEAU DES OPTIONS POUR LES INCOMES
export const getIncomes = async (
  t: TFunction,
  setIcomes: Dispatch<SetStateAction<IIncomes[] | null>>,
  datapost: any,
  setIsLoading?: Dispatch<SetStateAction<boolean>>
) => {
  if (setIsLoading) setIsLoading(true);
  try {
    const response = await axiosClient.post(urlGetIncomes, datapost);
    if (response && response.data) {
      setIcomes(constructIncomesContentArray(t, response.data));
    }
    if (setIsLoading) setIsLoading(false);
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
};

// CALCUL DES PRIMES ET ENVOI DES DATAS DE SIMULATION
export const calculatePrimes = async (
  simulatorDatas: ISimulatorData,
  simulatorDataOperation: ISimulatorDataOperation[],
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  updateStepSimulator: Dispatch<SetStateAction<number>>,
  updateSimulatorData: Dispatch<SetStateAction<ISimulatorData>>,
  updateSimulatorOperationData: Dispatch<
    SetStateAction<ISimulatorDataOperation[]>
  >,
  setErroMessage: Dispatch<SetStateAction<string>>,
  worksiteOperationType: number,
  conventionId: number,
  incomesArray: IIncomes[] | null,
  t: TFunction,
  incentiveType: number
) => {
  let income = null;

  const precarityType = simulatorDatas['general.income.precarityType'] || null;

  if (incomesArray && precarityType) {
    income =
      incomesArray.find((i) => i.value === precarityType)?.income || null;
  }

  if (income === null && worksiteOperationType === OperationTypeEnum.B2C) {
    toast.error(t('toast.errors.incomes_not_select'));
  } else {
    const sendData = {
      ...simulatorDatas,
      operations: Object.values(simulatorDataOperation),
      'convention.id': conventionId,
      operationType: worksiteOperationType,
      'general.income': income || undefined,
      incentiveType,
    };
    setIsLoading(true);

    try {
      const response = await axiosClient.post(urlPostCalculatePrimes, sendData);
      setIsLoading(false);
      const nextStep =
        worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION
          ? StepsWorksiteCreationEnum.GLOBAL_PRIMES
          : StepsWorksiteCreationEnum.SIMULATION_RECAPITULATIF;
      updateStepSimulator(nextStep);
      updateSimulatorData(response.data);
      updateSimulatorOperationData(response.data.operations);
    } catch (error) {
      setErroMessage(error.response.data.errors);
      setIsLoading(false);
    }
  }
};

export const calculateRenovationGlobale = async (
  data: FieldValues,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  updateSimulatorData: Dispatch<SetStateAction<ISimulatorData>>,
  updateSimulatorOperationData: Dispatch<
    SetStateAction<ISimulatorDataOperation[]>
  >
) => {
  try {
    const response = await axiosClient.post(urlPostCalculatePrimes, data);

    updateSimulatorData(response.data);
    updateSimulatorOperationData(response.data.operations);
    setIsLoading(false);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return false;
  }
};

interface validateWorksiteCreationParams {
  simulatorDatas: ISimulatorData;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  updateWorksiteDatas: Dispatch<SetStateAction<IWorksite>>;
  setMessageError: Dispatch<SetStateAction<string>>;
  setWorkflow: Dispatch<SetStateAction<IWorkflowSidebar | undefined>>;
  operationType: number;
  setCreationStep: Dispatch<SetStateAction<StepsWorksiteCreationEnum>>;
  sendToBeneficiary: boolean;
  worksiteId?: number;
}

// VALIDATION DE LA SIMULATION APRES SIMULATION ETAPE RECAP
export const validateWorksiteCreation = async ({
  simulatorDatas,
  setIsLoading,
  updateWorksiteDatas,
  setMessageError,
  setWorkflow,
  operationType,
  setCreationStep,
  sendToBeneficiary,
  worksiteId,
}: validateWorksiteCreationParams) => {
  setIsLoading(true);
  const { latitude, longitude } = simulatorDatas['general.address'];
  if (latitude !== 0 && longitude !== 0) {
    const parcelDatas = await getParcelMap({ lat: latitude, lng: longitude });
    if (parcelDatas) {
      const { parcelPrefix, parcelSection, parcelNumber } = parcelDatas;

      simulatorDatas.cadastral_parcel =
        (parcelPrefix ? `${parcelPrefix} ` : '') +
        (parcelSection ? `${parcelSection} ` : '') +
        (parcelNumber || '');
    }
  }
  try {
    const response = await axiosClient.post(urlPostWorksiteValidateCreation, {
      ...simulatorDatas,
      operationType,
      'worksite.id': worksiteId,
      sendToBeneficiary,
    });
    updateWorksiteDatas(response.data.data);

    if (operationType !== 3) {
      const resWorkflow = await getWorkflowSidebarSteps(
        response.data.data.id,
        setWorkflow,
        setIsLoading,
        operationType,
        response.data.data.worksites_operations
      );

      if (resWorkflow.creation && resWorkflow.creation.length > 0) {
        setCreationStep(resWorkflow.creation[0].value);
      } else {
        setCreationStep(
          StepsWorksiteCreationEnum.WORKSITE_CREATION_INFO_BENEFICIARY
        );
      }
    } else {
      setCreationStep(
        StepsWorksiteCreationEnum.WORKSITE_CREATION_SUBCONSTRACTOR
      );
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      setMessageError(error.response.data.errors);
    }
    setIsLoading(false);
  }
};

// LIST MATERIEL POUR SCOPUNIT
export const listMaterials = async (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setListMaterialsData: Dispatch<SetStateAction<IMaterials[] | null>>,
  keyword: string
) => {
  setIsLoading(true);
  const dataPost = {
    pagination: {
      page: 1,
      perPage: 100,
    },
    keyword,
    operation: {
      id: 64,
      code: 'BAR-TH-129',
      description: 'Pompe à chaleur de type air/air',
      can_have_material: true,
      incompatibilities: [
        'BAR-TH-104',
        'BAR-TH-129',
        'BAR-TH-106',
        'BAR-TH-159',
        'BAR-TH-113',
      ],
    },
  };

  try {
    const response = await axiosClient.post(urlMaterials, dataPost);
    setListMaterialsData(response.data[0]);
    setIsLoading(false);
  } catch (error) {
    toast.error(error.response.data.errors);
    setIsLoading(false);
  }
};

// RECUPERE LA LATITUDE ET LA LONGITUDE D'UNE VILLE
export const getLatLngWithZipCode = async (
  zipcode: string,
  setState: Dispatch<SetStateAction<{ lat: number; lng: number }>>
) => {
  try {
    const response = await axios.get(urlGetLatLngWithZipCode(zipcode));
    const { lat, lng } = response.data.results[0].geometry.location;
    setState({ lat, lng });
    return { lat, lng };
  } catch (error) {
    console.log(error);
    return null;
  }
};

// ENVOI LES INFORMATIONS DU BENEFICIAIRE A L'ETAPE INFOS BENEFICIAIRE DE LA CREATION DE CHANTIER
export const sendInfoBeneficiary = async (
  operationType: number,
  idWorksite: number,
  dataBeneficiary: IPayloadInfosBeneficiary,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setWorksiteDatas: Dispatch<SetStateAction<IWorksite>>,
  setMessageError: Dispatch<SetStateAction<string>>,
  payload: ISimulatorData | null
) => {
  setIsLoading(true);

  try {
    const noFiscalData =
      payload && payload['general.income'] === 4 && operationType === 3;

    const { fiscalDeclarations, ...editedData } = dataBeneficiary;

    const beneficiaryInfos = noFiscalData ? editedData : dataBeneficiary;

    const response = await axiosClient.post(
      urlPostWorksiteInfosBeneficiary(idWorksite),
      { ...beneficiaryInfos, operationType, payload }
    );
    setIsLoading(false);
    setWorksiteDatas(response.data.data);
    return true;
  } catch (error) {
    setMessageError(error.response.data.errors);
    setIsLoading(false);

    return false;
  }
};

// ENVOI LES INFORMATIONS DU LOGEMENT A L'ETAPE INFOS LOGEMENT DE LA CREATION DE CHANTIER
export const sendInfoLogement = async (
  idWorksite: number,
  dataLogement: IPayloadInfosLogement,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setWorksiteDatas: Dispatch<SetStateAction<IWorksite>>,
  setMessageError: Dispatch<SetStateAction<string>>
) => {
  setIsLoading(true);
  try {
    const response = await axiosClient.post(
      urlPostWorksiteInfosLogement(idWorksite),
      dataLogement
    );
    setIsLoading(false);
    setWorksiteDatas(response.data.data);
    return true;
  } catch (error) {
    setMessageError(error.response.data.errors);
    setIsLoading(false);

    return false;
  }
};

// ENVOI INFORMATIONS SOUS-TRAITANT A L'ETAPE SOUS-TRAITANT DE LA CREATION DE CHANTIER
export const sendSubcontractorToWorksiteOperations = async (
  idWorksite: number,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string>>,
  payload: IPayloadSubcontractor[],
  updateWorksiteDatas: Dispatch<SetStateAction<IWorksite>>
) => {
  setIsLoading(true);
  const dataPost = {
    payload,
  };
  try {
    const response = await axiosClient.post(
      urlPostWorksiteInfosSubcontractors(idWorksite),
      dataPost
    );
    updateWorksiteDatas(response.data.data);
    setIsLoading(false);
    return true;
  } catch (error) {
    setError(error.response.data.errors);
    setIsLoading(false);
    return false;
  }
};

// RECUPERE LA LISTE DES MATERIAUX PAR OPERATION
export const getListMeterialsPerOperation = async (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  keyword: string,
  page: number,
  code: string,
  setListMaterials: Dispatch<SetStateAction<IMaterial[]>>,
  isScrolling: boolean,
  setLastPage: Dispatch<SetStateAction<number>>,
  mark: string,
  internal: boolean
) => {
  setIsLoading(true);
  const dataPost = {
    pagination: {
      page,
      perPage: 15,
    },
    keyword,
    code,
    mark,
    internal,
  };
  try {
    const response = await axiosClient.post(urlMaterialProducts, dataPost);

    if (isScrolling) {
      setListMaterials((prevState) => [...prevState, ...response.data.data]);
    } else {
      setListMaterials(response.data.data);
    }

    setLastPage(Number(response.data.last_page));

    setIsLoading(false);
    return true;
  } catch (error) {
    setIsLoading(false);
    return false;
  }
};

// ENVOI LE MATERIEL DES OPERATIONS A L'ETAPE MATERIEL DE LA CREATION DE CHANTIER
export const sendMaterialToWorksiteOperation = async (
  worksiteId: number,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  payload: IPayloadMaterials[] | null,
  updateWorksiteDatas: Dispatch<SetStateAction<IWorksite>>,
  setErrorMaterial: Dispatch<SetStateAction<string>>
) => {
  setIsLoading(true);

  const dataPost = {
    materials: payload,
  };
  try {
    const response = await axiosClient.post(
      urlPostWorksiteInfosMaterials(worksiteId),
      dataPost
    );

    setIsLoading(false);

    updateWorksiteDatas(response.data.data);
    return true;
  } catch (error) {
    setErrorMaterial(error.response.data.errors);
    setIsLoading(false);
    return false;
  }
};

// RECUPERE LES INFORMATIONS DU CHANTIER
export const getWorksite = async (
  t: TFunction,
  idWorksite: number,
  updateFunctions: {
    updateWorksiteDatas: Dispatch<SetStateAction<IWorksite>>;
    updateSimulatorData?: Dispatch<SetStateAction<ISimulatorData>>;
    updateSimulatorDataOperation?: Dispatch<
      SetStateAction<ISimulatorDataOperation[]>
    >;
    updateWorksiteAddress?: (updates: Partial<IWorksiteAddress>) => void;
    setIsLoading?: Dispatch<SetStateAction<boolean>>;
  },
  router: NavigateFunction,
  navigateTo = WORKSITES_ROUTES.WORKSITES
) => {
  const {
    updateWorksiteDatas,
    updateSimulatorData,
    updateSimulatorDataOperation,
    updateWorksiteAddress,
    setIsLoading,
  } = updateFunctions;

  if (setIsLoading) {
    setIsLoading(true);
  }
  try {
    const response = await axiosClient.get(urlGetOrDeleteWorksite(idWorksite));
    const simulatorDatasPayload = JSON.parse(response.data.data.payload);
    if (setIsLoading) {
      setIsLoading(false);
    }
    updateWorksiteDatas(response.data.data);
    if (updateSimulatorData) {
      updateSimulatorData(simulatorDatasPayload);
    }

    if (updateSimulatorDataOperation && simulatorDatasPayload.operations) {
      updateSimulatorDataOperation(simulatorDatasPayload.operations);
    }

    if (updateWorksiteAddress) {
      const addressFromPayload = {
        ...simulatorDatasPayload['general.address'],
        postal_code: simulatorDatasPayload['general.zipcode'],
      };
      const addressObj = response.data.data.address;
      const addressToSet = addressObj || addressFromPayload;

      let streetNumber = null;
      let streetName = null;

      if (addressToSet.address) {
        const [numberStreet, ...rest] = addressToSet.address.split(' ');
        streetName = rest.join(' ');
        streetNumber = numberStreet;
      }

      updateWorksiteAddress({
        numberStreet: streetNumber || undefined,
        streetName: streetName || undefined,
        zipCode: addressToSet.postal_code || undefined,
        city: addressToSet.city || undefined,
        country: addressToSet.country || undefined,
        longitude: addressToSet.longitude && Number(addressToSet.longitude),
        latitude: addressToSet.latitude && Number(addressToSet.latitude),
      });
    }
    return response.data.data;
  } catch (error) {
    console.log(error);
    if (
      error.response?.data?.errors instanceof Array &&
      error.response?.data?.errors.some((request: any) =>
        request.includes('No query results for model')
      )
    ) {
      toast.info(t('worksites.bad_request'));
      router(navigateTo);
    } else if (error.response?.status === 403) {
      toast.error(t('global.forbidden'));
      router(navigateTo);
    } else {
      toast.error(t('global.error'));
    }
    return false;
  }
};

// SUPPRIMER UN CHANTIER
export const deleteWorksite = async (
  idWorksite: number,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  updateIsModal: Dispatch<
    SetStateAction<{
      formBeforeSimulation: boolean;
      bindBeneficiary: boolean;
      createBeneficiary: boolean;
      saveAndQuit: boolean;
      deleteWorksite: boolean;
    }>
  >,
  router: NavigateFunction
) => {
  try {
    setIsLoading(true);
    await axiosClient.delete(urlGetOrDeleteWorksite(idWorksite));
    updateIsModal({
      formBeforeSimulation: true,
      bindBeneficiary: false,
      createBeneficiary: false,
      saveAndQuit: false,
      deleteWorksite: false,
    });
    router(WORKSITES_ROUTES.WORKSITES);
    return true;
  } catch (error) {
    toast.error(error.response.data.errors);
    setIsLoading(false);
    return false;
  }
};

// GENERATION DE LA NOTE DE DIMENSIONNEMENT
export const generateSizingNote = async (
  worksiteDatas: IWorksite,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  payload: IPayloadSizingNote,
  setError: Dispatch<SetStateAction<string>>,
  updateWorksiteDatas: Dispatch<SetStateAction<IWorksite>>
) => {
  let newRommHeight = 0;
  if (payload.room_height) {
    newRommHeight = parseFloat(payload.room_height.replace(',', '.'));
  }
  const sendData = {
    generate: payload.generate,
    room_height: newRommHeight,
    altitude: payload.altitude,
    temperature_mode: payload.temperature_mode,
    transmitter_type: payload.transmitter_type,
    construction_type: payload.construction_type,
    heat_pump_shutdown_temperature: payload.heat_pump_shutdown_temperature,
    power_temperature: payload.power_temperature,
    electrical_resistance_power: payload.electrical_resistance_power,
    other_heating: payload.other_heating,
  };

  try {
    const response = await axiosClient.put(
      `/worksites/${worksiteDatas.id}/sizing_note`,
      sendData
    );
    setIsLoading(false);
    updateWorksiteDatas({
      ...response.data.data,
      worksites_operations: worksiteDatas.worksites_operations,
    });
    return true;
  } catch (error) {
    setError(error.response.data.errors);
    setIsLoading(false);
    return false;
  }
};

// ENVOI DE LA NOTE DE DIMENSIONNEMENT
export const sendSizingNote = async (
  idWorksite: number,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string>>,
  updateWorksiteDatas: Dispatch<SetStateAction<IWorksite>>
) => {
  try {
    const response = await axiosClient.get(
      `/worksites/${idWorksite}/sizing_note`
    );
    setIsLoading(false);
    updateWorksiteDatas(response.data.data);
    return true;
  } catch (error) {
    setError(error.response.data.errors);
    setIsLoading(false);
    return false;
  }
};

// VALIDATION CHANTIER APRES UPLOAD DES DOCUMENTS
export const validateWorksiteAfterUploadFiles = async (
  idWorksite: number,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  signedQuotesToSend: ILinkedFile[],
  router?: NavigateFunction
) => {
  try {
    await axiosClient.post(
      urlPostWorksiteValidateAfterUploadFiles(idWorksite),
      {
        signed_quotes: signedQuotesToSend,
      }
    );
    if (router) {
      router(WORKSITES_ROUTES_WITH_ID(idWorksite as number).WORKSITES_VIEW);
      setIsLoading(false);
    }
    return true;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
    setIsLoading(false);
    return false;
  }
};

export const sendCTAToBeRecontactedNotification = async (t: TFunction) => {
  try {
    const response = await axiosClient.post(
      urlPostSendCTAToBeRecontactedNotification
    );
    toast.success(t('partners.contact_update_success'));
    return response.data;
  } catch (error) {
    toast.error(error.response.data.errors);
    return false;
  }
};

export const getMaterialBrandsList = async (
  keyword: string | null,
  setListBrands: Dispatch<SetStateAction<{ name: string; id: number }[]>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  try {
    setIsLoading(true);
    const pagination = { page: 1, perPage: 100 };
    const response = await axiosClient.post(urlMaterialBrands, {
      keyword,
      pagination,
    });

    setListBrands(response.data.data);
    setIsLoading(false);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.errors);
    setIsLoading(false);
    return false;
  }
};
